import React, { useContext } from 'react';
import cn from 'classnames';
import { WidthContext } from 'contexts/width-context';
import { Book } from 'components';
import { MainTitle, Link } from 'ui-kit';
import s from '../book.module.scss';

function SimularBooks({ books, match, scrollPosition }) {
  const { isTabDown } = useContext(WidthContext);
  return (
    <div className={cn(s.block, s.simular)}>
      <div className={s.head}>
        <MainTitle className={s.mainTitle} texts={['Похожие', 'книги']} />
        <Link className={s.link} data-v2 to="/books">
          {!isTabDown && 'показать все'}
          <img src="/images/svg/arrow-left-v2.svg" alt="" />
        </Link>
      </div>
      <div className={s.books}>
        {books?.map((item, key) => (
          <Book
            {...item}
            key={key}
            match={match}
            scrollPosition={scrollPosition}
            className={s.book}
          />
        ))}
      </div>
    </div>
  );
}

export default SimularBooks;

import React from 'react';
import cn from 'classnames';

import s from './textarea.module.scss';

const Textarea = ({
  label,
  icon,
  className,
  placeholder,
  iconPos = 'left',
  children,
  ...props
}) => {
  return (
    <div
      className={cn(
        s.textarea,
        className,
        icon && s.withIcon,
        icon && s[iconPos]
      )}
    >
      {icon && (
        <label>
          <img className={s.icon} src={`/images/svg/${icon}.svg`} alt="icon" />
        </label>
      )}
      <textarea placeholder={placeholder} {...props} />
      {children}
    </div>
  );
};

export default Textarea;

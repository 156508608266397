import axiosInstance from 'axios';
import { makeUseAxios } from 'axios-hooks';

let url = process.env.REACT_APP_DEV_API_ENDPOINT;

if (isProd) {
  url = process.env.REACT_APP_API_ENDPOINT;
}

export const axios = axiosInstance.create({
  timeout: 30000,
  baseURL: url,
});

export const useAxios = makeUseAxios({
  axios,
});

import React from 'react';
import cn from 'classnames';

import { MainTitle } from 'ui-kit';
import s from '../book.module.scss';

function BookInformation({ book, versionNumber }) {
  const { description, language = [], versions } = book;
  let info;
  if (versions[versionNumber - 1]) {
    info = versions[versionNumber - 1].info;
  } else {
    info = { pages: '', copies: '', height: '', width: '' };
  }

  const { pages, copies, height, width } = info;

  return (
    <div className={cn(s.block, s.information)}>
      <div className={s.head}>
        <MainTitle className={s.mainTitle} texts={['Информация', 'о книге']} />
      </div>
      <div className={s.description}>{description}</div>
      <ul className={s.list}>
        {language && (
          <li className={s.item}>
            <span className={s.label}>Язык:</span>
            <span className={s.value}>
              {language === 'ru' ? ' Русский' : ' Украинский'}
            </span>
          </li>
        )}
        {!!pages && (
          <li className={s.item}>
            <span className={s.label}>Страниц: </span>
            <span className={s.value}>{pages}</span>
          </li>
        )}
        {!!copies && (
          <li className={s.item}>
            <span className={s.label}>Тираж: </span>
            <span className={s.value}>{copies}</span>
          </li>
        )}
        {!!width && !!height && (
          <li className={s.item}>
            <span className={s.label}>Формат: </span>
            <span className={s.value}>
              {width}х{height}
            </span>
          </li>
        )}
      </ul>
    </div>
  );
}

export default BookInformation;

import React, { useEffect, useState, useReducer, useRef } from 'react';
import cn from 'classnames';
import { Helmet } from 'react-helmet';
import { withTranslation } from 'react-i18next';
import { toast } from 'react-toastify';

import DatePicker from 'react-date-picker/dist/entry.nostyle';
import ContentEditable from 'react-contenteditable';
// import CKEditor from '@ckeditor/ckeditor5-react'
import { slugify } from 'transliteration';
import { langs } from 'i18n';
import {
  Button,
  Input,
  Breadcrumbs,
  Container,
  Spin,
  Tabs,
  Tab,
  TabsPanel,
  TabContainer,
  TabContent,
  Dropzone,
  Switch,
  Select,
  SelectOption,
} from 'ui-kit';
import { ControlTooltip } from './components';
import s from './create-article.module.scss';

const CreateArticle = ({ t, className }) => {
  const initialState = {
    lang: langs[0],
    isLoading: false,
    editorLoaded: false,
    copyFrom: 'empty',
    // selectedField: '',
    validationLures: {
      thumbnail: {
        height: 1200,
        width: 1200,
      },
      socialImage: {
        height: 1200,
        width: 1200,
      },
    },
    emptyArticle: {
      // isPublic: false,
      isEnabled: false,
      isCustomSlug: false,
      articleTitle: '',
      author: '',
      thumbnail: '',
      socialImage: '',
      articleImage: '',
      previewDescription: '',
      articleContent: '',
      publishDate: new Date(),
      slug: '',
      pageTitle: '',
      pageDescription: '',
      socialTitle: '',
      socialDescription: '',
      pageKeywords: '',
      articlePublishedTime: '',
      articleModifiedTime: '',
    },
    defaultArticle: {
      // isPublic: false,
      isEnabled: false,
      isCustomSlug: false,
      articleTitle: 'Default article name',
      author: '',
      thumbnail: '',
      socialImage: '',
      articleImage: '',
      previewDescription: 'Default Preview Description',
      articleContent: '<p>Default article content.</p>',
      publishDate: new Date(),
      slug: 'zagolovok-stranicy',
      pageTitle: 'Заголовок страницы',
      pageDescription: 'Описание страницы',
      socialTitle: '',
      socialDescription: '',
      pageKeywords: 'Ключевые фразы',
      articlePublishedTime: '',
      articleModifiedTime: '',
    },
    translates: {
      ru: {
        // isPublic: false,
        isEnabled: false,
        isCustomSlug: false,
        lang: 'ru',
        articleTitle: '',
        author: '',
        thumbnail: '',
        socialImage: '',
        articleImage: '',
        previewDescription: '',
        articleContent: '',
        publishDate: new Date(),
        slug: '',
        pageTitle: '',
        pageDescription: '',
        socialTitle: '',
        socialDescription: '',
        pageKeywords: '',
        articlePublishedTime: '',
        articleModifiedTime: '',
      },
    },
  };
  const reducer = (state, newState) => ({ ...state, ...newState });

  const [state, setState] = useReducer(reducer, initialState);

  const {
    copyFrom,
    isLoading,
    editorLoaded,
    translates,
    emptyArticle,
    defaultArticle,
    lang,
  } = state;

  const [html, setHtml] = useState('');

  const editorRef = useRef();
  const { CKEditor, InlineEditor } = editorRef.current || {};

  useEffect(() => {
    editorRef.current = {
      CKEditor: require('@ckeditor/ckeditor5-react'),
      InlineEditor: require('@ckeditor/ckeditor5-build-inline'),
    };

    setState({
      editorLoaded: true,
    });

    // localStorage.setItem('article', JSON.stringify({ ...state, editorLoaded: true }))
    //
    // window.onbeforeunload = function () {
    //   if (localStorage.getItem('article') !== JSON.stringify(state)) {
    //     return 'Are you serious ?'
    //   }
    // };
  }, []);
  const articleContent = translates[lang]?.articleContent;

  useEffect(() => {
    setHtml(articleContent);
  }, [articleContent]);

  const updateAtricle = (lang, field) => {
    const isExistLang = Object.keys(translates).includes(lang);
    const isSlug =
      // eslint-disable-next-line no-prototype-builtins
      field.hasOwnProperty('pageTitle') && !translates[lang].isCustomSlug;

    const updatedTranslates = {
      translates: {
        ...translates,
        [lang]: {
          ...(isExistLang
            ? {
                ...translates[lang],
              }
            : {
                ...emptyArticle,
                lang,
              }),
          ...(isSlug && {
            slug: slugify(field.pageTitle),
          }),
          ...field,
        },
      },
    };

    // localStorage.setItem('article', JSON.stringify({ ...state, ...updatedTranslates }))

    setState({ ...updatedTranslates });
  };

  const selectCopyFrom = (e) => {
    const copyFrom = e.target.value;
    setState({
      copyFrom,
    });
  };

  const applyCopyFrom = (lang) => {
    const data =
      copyFrom === 'empty'
        ? emptyArticle
        : copyFrom === 'default'
        ? defaultArticle
        : translates[copyFrom];

    setState({
      translates: {
        ...translates,
        [lang]: {
          ...data,
          lang,
        },
      },
    });
  };

  const changeTab = (lang) => {
    setState({
      lang,
      ...(!Object.keys(translates).includes(lang)
        ? {
            translates: {
              ...translates,
              [lang]: {
                ...emptyArticle,
                lang,
              },
            },
          }
        : {}),
    });

    // if (Object.keys(translates).includes(lang)) {
    //   setHtml(translates[lang]['articleContent'])
    // } else {
    //   setHtml('')
    // }
  };

  // TODO move regions object to variables file

  const regions = {
    uk: 'UA',
    ru: 'RU',
    en: 'US',
  };

  const handleSave = () => {
    toast.info('🦄 Wow so easy!', {
      position: 'top-right',
      autoClose: 5000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
    });
  };

  const {
    isEnabled,
    publishDate,
    isCustomSlug,
    pageTitle,
    pageDescription,
    slug,
    socialTitle,
    socialDescription,
    pageKeywords,
    thumbnail,
    socialImage,
    articleTitle,
    articleImage,
    author,
    previewDescription,
    articlePublishedTime,
    articleModifiedTime,
  } = translates[lang];

  return (
    <div className={cn(className, s.createArticle)}>
      <Helmet
        title={pageTitle}
        meta={[
          { name: 'description', content: pageDescription },
          { name: 'keywords', content: pageKeywords },
          { property: 'og:title', content: socialTitle }, // try changed porperty => name
          { property: 'og:type', content: 'article' }, // try changed porperty => name
          {
            property: 'og:locale',
            content: `${lang}_${regions[lang]?.toUpperCase()}`,
          }, // try changed porperty => name
          { property: 'og:url', content: `${HOST}/${slug}` }, // try changed porperty => name
          { property: 'og:image', content: `${HOST}/images/seo-img.jpg` }, // try changed porperty => name
          { property: 'og:image:type', content: 'image/jpeg' }, // try changed porperty => name
          // { property: 'fb:admins', content: 'fb:admins' }, // try changed porperty => name
          { property: 'og:site_name', content: 'Lifesource' }, // try changed porperty => name
          { property: 'og:description', content: socialDescription }, // try changed porperty => name
          { property: 'og:image:width', content: '1200' }, // try changed porperty => name
          { property: 'og:image:height', content: '1200' }, // try changed porperty => name
          // twitter
          { name: 'twitter:card', content: 'summary' },
          { name: 'twitter:site', content: HOST },
          { name: 'twitter:creator', content: 'Lifesource' },

          { name: 'article:published_time', content: articlePublishedTime },
          { name: 'article:modified_time', content: articleModifiedTime },

          // { name: 'author', content: authorName }, // TODO: add this line
        ]}
      />
      <Container>
        <Container full={false}>
          <Breadcrumbs
            paths={[
              {
                title: 'Create Article',
                path: '/create-article',
              },
            ]}
          />

          <Container className={s.container}>
            <Container full={false}>
              <div className={s.panelControl}>
                <div className={s.field}>
                  <Switch
                    checked={isEnabled}
                    onChange={(e) =>
                      updateAtricle(lang, { isEnabled: e.target.checked })
                    }
                  />
                  <div className={s.label}>
                    Опубликовать
                    <div className={s.explanation}>
                      Switch off to remove from all public resources
                    </div>
                  </div>
                </div>
                <div className={s.controls}>
                  <Button className={s.button} onClick={handleSave}>
                    Сохранить
                  </Button>
                  <Button className={s.button}>Отменить</Button>
                </div>
              </div>
            </Container>
          </Container>
          <Tabs>
            <TabsPanel>
              {isLoading ? (
                <Spin className={s.spin} />
              ) : (
                langs.map((lang) => (
                  <Tab
                    key={lang}
                    text={lang}
                    onChange={() => changeTab(lang)}
                    icon={isEnabled && 'checked.svg'}
                    className={cn(s.tab, !isEnabled && s.disabled)}
                  />
                ))
              )}
            </TabsPanel>
            <TabContainer className={s.tabContainer}>
              {langs.map((lang) => {
                // const isEnabled = translates[lang]?.isEnabled || false
                // const publishDate = translates[lang]?.publishDate
                // const isCustomSlug = translates[lang]?.isCustomSlug
                // const pageTitle = translates[lang]?.pageTitle
                // const articleTitle = translates[lang]?.articleTitle
                // const pageDescription = translates[lang]?.pageDescription
                // const previewDescription = translates[lang]?.previewDescription
                // const socialTitle = translates[lang]?.socialTitle
                // const socialDescription = translates[lang]?.socialDescription
                // const pageKeywords = translates[lang]?.pageKeywords
                // const slug = translates[lang]?.slug
                return (
                  <TabContent key={lang}>
                    <fieldset>
                      <div className={cn(s.field, s.template)}>
                        <div className={s.title}>Template</div>
                        <Select
                          value={copyFrom}
                          className={s.select}
                          onChange={selectCopyFrom}
                        >
                          <SelectOption value="empty" text="Empty" />
                          <SelectOption value="default" text="Default" />
                          {Object.keys(translates).map(
                            (item) =>
                              item !== lang && (
                                <SelectOption
                                  key={item}
                                  value={item}
                                  text={item}
                                />
                              )
                          )}
                        </Select>
                        <Button
                          className={s.button}
                          onClick={() => applyCopyFrom(lang)}
                          children="Предзаполнить"
                        />
                        <div className={s.explanation}>
                          Swith off to remove from all public resources
                        </div>
                      </div>
                    </fieldset>

                    <fieldset>
                      <legend>SEO</legend>
                      <div
                        className={cn(
                          s.field,
                          pageTitle.length > 80 && s.warning
                        )}
                      >
                        <div className={s.title}>
                          Page Title
                          <div className={s.info}>
                            <span>{pageTitle.length}</span> / 80
                          </div>
                        </div>
                        <Input
                          type="text"
                          value={pageTitle}
                          className={s.input}
                          onChange={(e) =>
                            updateAtricle(lang, {
                              pageTitle: e.target.value,
                            })
                          }
                        />
                        <div className={s.explanation}>
                          Swith off to remove from all public resources
                        </div>
                      </div>

                      <div
                        className={cn(
                          s.field,
                          s.slug,
                          slug.length > 160 && s.warning
                        )}
                      >
                        <div className={s.title}>
                          URL / Slug
                          <div className={s.info}>
                            <span>{slug.length}</span> / 160
                          </div>
                        </div>
                        <div className={s.inputWithSwitcher}>
                          <Input
                            disabled={!isCustomSlug}
                            type="text"
                            value={slug}
                            className={s.input}
                            onChange={(e) =>
                              updateAtricle(lang, {
                                slug: e.target.value,
                              })
                            }
                          />
                          <Switch
                            className={s.switch}
                            checked={isCustomSlug}
                            onChange={(e) =>
                              updateAtricle(lang, {
                                isCustomSlug: e.target.checked,
                              })
                            }
                          />
                        </div>
                        <div className={s.explanation}>
                          Based on the page title
                        </div>
                      </div>

                      <div
                        className={cn(
                          s.field,
                          pageDescription.length > 160 && s.warning
                        )}
                      >
                        <div className={s.title}>
                          Page Description
                          <div className={s.info}>
                            <span>{pageDescription.length}</span> / 160
                          </div>
                        </div>
                        <Input
                          type="text"
                          value={pageDescription}
                          className={s.input}
                          onChange={(e) =>
                            updateAtricle(lang, {
                              pageDescription: e.target.value,
                            })
                          }
                        />
                        <div className={s.explanation}>
                          Swith off to remove from all public resources
                        </div>
                      </div>

                      <div
                        className={cn(
                          s.field,
                          socialTitle.length > 160 && s.warning
                        )}
                      >
                        <div className={s.title}>
                          Social Title
                          <div className={s.info}>
                            <span>{socialTitle.length}</span> / 160
                          </div>
                        </div>
                        <Input
                          type="text"
                          value={socialTitle}
                          className={s.input}
                          onChange={(e) =>
                            updateAtricle(lang, {
                              socialTitle: e.target.value,
                            })
                          }
                        />
                        <div className={s.explanation}>
                          Swith off to remove from all public resources
                        </div>
                      </div>

                      <div
                        className={cn(
                          s.field,
                          socialDescription.length > 160 && s.warning
                        )}
                      >
                        <div className={s.title}>
                          Social Description
                          <div className={s.info}>
                            <span>{socialDescription.length}</span> / 160
                          </div>
                        </div>
                        <Input
                          type="text"
                          value={socialDescription}
                          className={s.input}
                          onChange={(e) =>
                            updateAtricle(lang, {
                              socialDescription: e.target.value,
                            })
                          }
                        />
                        <div className={s.explanation}>
                          Swith off to remove from all public resources
                        </div>
                      </div>

                      <div
                        className={cn(
                          s.field,
                          pageKeywords.length > 160 && s.warning
                        )}
                      >
                        <div className={s.title}>
                          Page Keywords
                          <div className={s.info}>
                            <span>{pageKeywords.length}</span> / 160
                          </div>
                        </div>
                        <Input
                          type="text"
                          value={pageKeywords}
                          className={s.input}
                          onChange={(e) =>
                            updateAtricle(lang, {
                              pageKeywords: e.target.value,
                            })
                          }
                        />
                        <div className={s.explanation}>
                          No more than 10 keyword phrases.
                        </div>
                      </div>
                    </fieldset>

                    <fieldset>
                      <legend>Images</legend>
                      <div className={s.images}>
                        <div className={cn(s.field, s.dropzone)}>
                          <div className={s.title}>
                            Thumbnail
                            <div className={s.info}>390x300</div>
                          </div>
                          <Dropzone
                            className={cn(s.dropzone, thumbnail && s.withImage)}
                            onDrop={(file, height, width) => {
                              updateAtricle(lang, { thumbnail: file });
                            }}
                          >
                            {thumbnail ? (
                              <div
                                className={s.withImage}
                                style={{ backgroundImage: `url(${thumbnail})` }}
                              >
                                <div className={s.action}>
                                  <img src="/images/svg/upload.svg" alt="" />
                                </div>
                                <div
                                  className={s.action}
                                  onClick={(e) => {
                                    e.stopPropagation();
                                    updateAtricle(lang, { thumbnail: '' });
                                  }}
                                >
                                  <img src="/images/svg/basket.svg" alt="" />
                                </div>
                              </div>
                            ) : (
                              <div
                                className={s.empty}
                                style={{
                                  backgroundImage: `url(/images/svg/empty-dropzone.svg)`,
                                }}
                              >
                                <div className={s.action}>
                                  <img src="/images/svg/upload.svg" alt="" />
                                </div>
                              </div>
                            )}
                          </Dropzone>
                        </div>
                        <div className={cn(s.field, s.dropzone)}>
                          <div className={s.title}>
                            Social Image
                            <div className={s.info}>1200x1200</div>
                          </div>
                          <Dropzone
                            className={cn(
                              s.dropzone,
                              socialImage && s.withImage
                            )}
                            onDrop={(file) =>
                              updateAtricle(lang, { socialImage: file })
                            }
                          >
                            {socialImage ? (
                              <div
                                className={s.withImage}
                                style={{
                                  backgroundImage: `url(${socialImage})`,
                                }}
                              >
                                <div className={s.action}>
                                  <img src="/images/svg/upload.svg" alt="" />
                                </div>
                                <div
                                  className={s.action}
                                  onClick={(e) => {
                                    e.stopPropagation();
                                    updateAtricle(lang, { socialImage: '' });
                                  }}
                                >
                                  <img src="/images/svg/basket.svg" alt="" />
                                </div>
                              </div>
                            ) : (
                              <div
                                className={s.empty}
                                style={{
                                  backgroundImage: `url(/images/svg/empty-dropzone.svg)`,
                                }}
                              >
                                <div className={s.action}>
                                  <img src="/images/svg/upload.svg" alt="" />
                                </div>
                              </div>
                            )}
                          </Dropzone>
                        </div>
                      </div>
                    </fieldset>

                    <fieldset>
                      <legend>Preview</legend>
                      <div className={cn(s.field, s.preview)}>
                        <div className={s.title}>Google</div>
                        <div className={s.googlePreview}>
                          <div className={s.searchTitle}>
                            {pageTitle.length ? pageTitle : 'Fill Page Title'}
                          </div>
                          <div className={s.searchUrl}>
                            <cite>{`${HOST}/articles/${slug}`}</cite>
                          </div>
                          <div className={s.searchDescription}>
                            {pageDescription.length
                              ? pageDescription
                              : 'Fill Page Description'}
                          </div>
                        </div>
                      </div>
                    </fieldset>
                  </TabContent>
                );
              })}
            </TabContainer>
          </Tabs>

          <div className={s.article}>
            <ControlTooltip
              title="Article title"
              isShow={articleTitle.length}
            />
            <div className={s.articleField}>
              <ContentEditable
                className={cn(s.contenteditable, s.articleTitle)}
                tagName="h3"
                placeholder="Add Article Title ..."
                html={articleTitle}
                disabled={false}
                name="articleTitle"
                onFocus={() => {}}
                onChange={(e) => {
                  updateAtricle(lang, {
                    articleTitle: e.target.value,
                  });
                }}
              />
            </div>

            <div className={s.articleField}>
              <ContentEditable
                className={cn(s.contenteditable, s.previewDescription)}
                tagName="h3"
                placeholder="Add Article Preview Description ..."
                html={previewDescription}
                disabled={false}
                onChange={(e) =>
                  updateAtricle(lang, {
                    previewDescription: e.target.value,
                  })
                }
              />
            </div>

            <div className={s.articleInfo}>
              <div className={s.articleField}>
                <span className={s.dash}>—</span>
                <DatePicker
                  onChange={(date) =>
                    updateAtricle(lang, { publishDate: date })
                  }
                  value={publishDate}
                />
              </div>

              <div className={s.articleField}>
                <span className={s.dash}>—</span>
                <ContentEditable
                  className={cn(s.contenteditable, s.author)}
                  tagName="h3"
                  placeholder="Add Author ..."
                  html={author}
                  disabled={false}
                  onChange={(e) =>
                    updateAtricle(lang, {
                      author: e.target.value,
                    })
                  }
                />
              </div>
            </div>

            <Dropzone
              className={s.dropzone}
              onDrop={(file) => updateAtricle(lang, { articleImage: file })}
            >
              {articleImage ? (
                <div
                  className={s.withImage}
                  style={{ backgroundImage: `url(${articleImage})` }}
                >
                  <div className={s.action}>
                    <img src="/images/svg/upload.svg" alt="" />
                  </div>
                  <div
                    className={s.action}
                    onClick={(e) => {
                      e.stopPropagation();
                      updateAtricle(lang, { articleImage: '' });
                    }}
                  >
                    <img src="/images/svg/basket.svg" alt="" />
                  </div>
                </div>
              ) : (
                <div className={s.empty}>
                  <img src="/images/svg/image.svg" alt="" />
                  <div className={s.title}>Title image</div>
                  <div className={s.text}>
                    Drag&Drop or choose an image to add it to the article
                  </div>
                  <div className={s.action}>
                    {/* <img src="/images/svg/upload.svg" alt="" /> */}
                    Browse
                  </div>
                </div>
              )}
            </Dropzone>

            {editorLoaded && (
              <CKEditor
                placeholder="Добавить текст статьи"
                className={s.ckeditor}
                editor={InlineEditor}
                data={html}
                // config={{
                //   plugins: [ CKFinder ],
                //   ckfinder: {
                //     uploadUrl: 'https://example.com/ckfinder/core/connector/php/connector.php?command=QuickUpload&type=Images&responseType=json'
                //   }
                // }}
                onInit={(editor) => {
                  editor.ui
                    .getEditableElement()
                    .parentElement.insertBefore(
                      editor.ui.view.toolbar.element,
                      editor.ui.getEditableElement()
                    );
                }}
                onChange={(event, editor) => {
                  const data = editor.getData();
                  updateAtricle(lang, { articleContent: data });
                }}
                onBlur={(event, editor) => {}}
                onFocus={(event, editor) => {}}
              />
            )}
          </div>
        </Container>
      </Container>
    </div>
  );
};

export default withTranslation()(CreateArticle);

import React, { useEffect, useState, useReducer } from 'react';
import cn from 'classnames';
import { Helmet } from 'react-helmet';
import { Collapse } from 'react-collapse';
// import { WidthContext } from 'contexts/width-context'
import { withTranslation } from 'react-i18next';
import { Issue, useDataApi } from 'components';
import { Breadcrumbs, Container, Spin, Pagination } from 'ui-kit';
import s from './all-issues.module.scss';

const AllIssues = ({
  i18n,
  t,
  className,
  match,
  location,
  history,
  isDefaultLang,
  scrollPosition,
  ...props
}) => {
  // const { isMob } = useContext(WidthContext)
  const initialPage = +location.search
    .slice(1)
    .split('?')[0]
    .replace('page=', '');

  const { language: lang } = i18n;

  if (!initialPage) {
    history.push(
      isDefaultLang ? `/all-issues?page=1` : `/${lang}/all-issues?page=1`
    );
  }

  const initilaFilters = {
    periodicalId: '',
    publishYear: '',
  };

  const reducer = (state, newState) => ({ ...state, ...newState });
  const [state, setState] = useReducer(reducer, initilaFilters);
  const [page, setPage] = useState(initialPage);
  const onPage = 12;
  const currentYear = new Date().getFullYear();

  const [{ data, isLoading }, setUrl] = useDataApi.getIssues({
    limit: onPage,
    offset: onPage * (page - 1),
  });

  const { periodicalId, publishYear } = state;

  useEffect(() => {
    const filters = {};

    for (const [key, value] of Object.entries(state)) {
      if (value) filters[`f.${key}`] = state[key];
    }

    setUrl({
      limit: onPage,
      offset: onPage * (page - 1),
      ...filters,
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [page, periodicalId, publishYear]);

  useEffect(() => {
    setPage(initialPage);
  }, [initialPage]);

  const { issues, meta } = data;

  const [dataPeriodicals] = useDataApi.getPeriodicals();

  const updateFilter = (newFilter) => {
    setState({
      ...state,
      ...newFilter,
    });
  };

  const [isOpenFilters, setIsOpenFilters] = useState(false);
  const toggleFilters = () => {
    setIsOpenFilters(!isOpenFilters);
  };

  return (
    <div className={cn(className, s.allIssues)}>
      <Helmet
        title="Issues"
        meta={[
          { name: 'description', content: 'Description for Issues page.' },
        ]}
      />
      <Container>
        <Container full={false}>
          <Breadcrumbs
            paths={[
              {
                title: 'all issues',
                path: '/all-issues',
              },
            ]}
          />
          <h1>
            {t('all-issues')}
            <div className={s.filterTitle} onClick={toggleFilters}>
              {/* {!isMob && ( */}
              <div className={s.text}>Фильтры</div>
              {/* )} */}
              <div className={cn(s.filtersIcon, isOpenFilters && s.open)}>
                <div />
                <div />
                <div />
              </div>
            </div>
          </h1>
          <Collapse isOpened={isOpenFilters}>
            <div className={s.filters}>
              <div className={s.filter}>
                <div className={s.title}>Категории:</div>
                <div className={s.options}>
                  {dataPeriodicals.data.periodicals?.map(({ id, title }) => {
                    const isActive = id === periodicalId;
                    return (
                      <div
                        key={id}
                        className={cn(s.option, isActive && s.active)}
                        onClick={
                          isActive
                            ? () => updateFilter({ periodicalId: '' })
                            : () => updateFilter({ periodicalId: id })
                        }
                      >
                        {title}
                        <img
                          alt="cross"
                          src="/images/svg/cross@1x.svg"
                          srcSet="/images/svg/cross@1x.svg 1x, /images/svg/cross@2x.svg 2x"
                          className={s.close}
                        />
                      </div>
                    );
                  })}
                </div>
              </div>
              <div className={s.filter}>
                <div className={s.title}>Год:</div>
                <div className={s.options}>
                  {Array(currentYear - 2015)
                    .fill()
                    .map((item, i) => {
                      const year = currentYear - i;
                      const isActive = year === state.publishyear;
                      return (
                        <div
                          key={year}
                          className={cn(s.option, isActive && s.active)}
                          onClick={
                            isActive
                              ? () => updateFilter({ publishyear: '' })
                              : () => updateFilter({ publishyear: year })
                          }
                        >
                          {year}
                          <img
                            alt="cross"
                            src="/images/svg/cross@1x.svg"
                            srcSet="/images/svg/cross@1x.svg 1x, /images/svg/cross@2x.svg 2x"
                            className={s.close}
                          />
                        </div>
                      );
                    })}
                </div>
              </div>
            </div>
          </Collapse>
          {isLoading ? (
            <Spin />
          ) : (
            <div className={cn(s.list, !isLoading && s.loaded)}>
              {issues?.map((issue, key) => (
                <Issue
                  {...issue}
                  scrollPosition={scrollPosition}
                  match={match}
                  key={key}
                  isAnimate
                />
              ))}
            </div>
          )}
          <Pagination {...meta} page={page} match={match} />
        </Container>
      </Container>
    </div>
  );
};

export default withTranslation()(AllIssues);

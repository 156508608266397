import React, { useState } from 'react';
import cn from 'classnames';

import { Spin } from 'ui-kit';
import s from './img.module.scss';

const Img = ({
  onLoad = () => {},
  onError = () => {},
  src,
  className,
  ...props
}) => {
  const DEFAUTL_BOOK_COVER = '/images/svg/defaultBook.svg';
  const [image, setImage] = useState(src || DEFAUTL_BOOK_COVER);
  const [isLoading, setIsLoading] = useState(true);

  const onLoadHandler = () => {
    setIsLoading(false);
    onLoad();
  };

  const onErrorHandler = (e) => {
    setImage(DEFAUTL_BOOK_COVER);
    setIsLoading(false);
    onError();
  };

  const isDefault = image === DEFAUTL_BOOK_COVER;

  return (
    <div
      className={cn(
        s.img,
        className,
        isDefault && s.default,
        isLoading && s.loading
      )}
    >
      <img
        alt=""
        {...props}
        src={image}
        onLoad={onLoadHandler}
        onError={onErrorHandler}
      />
      {isLoading && (
        <div className={s.spinWrap}>
          <Spin className={s.spin} />
        </div>
      )}
    </div>
  );
};

export default Img;

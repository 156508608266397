import React, { useEffect, useState } from 'react';
import cn from 'classnames';
import { Helmet } from 'react-helmet';
import { withTranslation } from 'react-i18next';
import { Periodical, useDataApi } from 'components';
import { getSearchParams } from 'utils';
import { Breadcrumbs, Container, Spin, Pagination } from 'ui-kit';
import s from './periodicals.module.scss';

const Periodicals = ({
  t,
  className,
  match,
  history,
  location,
  i18n,
  scrollPosition,
}) => {
  const params = getSearchParams(location.search);

  const [page, setPage] = useState(params.page || 1);
  const onPage = 12;

  const [{ data, isLoading }, setUrl] = useDataApi.getPeriodicals({
    public: true,
    versions: true,
    limit: onPage,
    offset: onPage * (page - 1),
  });

  useEffect(() => {
    setUrl({
      public: true,
      versions: true,
      limit: onPage,
      offset: onPage * (page - 1),
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [page]);

  useEffect(() => {
    if (params.page) setPage(params.page);
  }, [params.page]);

  const { periodicals, meta } = data;

  return (
    <div className={cn(className, s.periodicals)}>
      <Helmet
        title="Periodicals"
        meta={[
          { name: 'description', content: 'Description for Periodicals page.' },
        ]}
      />
      <Container>
        <Container full={false}>
          <Breadcrumbs
            paths={[
              {
                title: 'periodicals',
                path: '/periodicals',
              },
            ]}
          />
          <h1>{t('periodicals')}</h1>
          {isLoading ? (
            <Spin />
          ) : (
            <div className={cn(s.list, !isLoading && s.loaded)}>
              {periodicals?.map((periodical, key) => (
                <Periodical
                  {...periodical}
                  match={match}
                  key={key}
                  isAnimate
                  className={s.periodical}
                  scrollPosition={scrollPosition}
                />
              ))}
            </div>
          )}
          <Pagination {...meta} page={page} match={match} />
        </Container>
      </Container>
    </div>
  );
};

export default withTranslation()(Periodicals);

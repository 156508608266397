import React from 'react';
import cn from 'classnames';

import s from './select.module.scss';

const Select = ({ className, ...props }) => (
  <div className={cn(s.select, className)}>
    <select name="" id="" {...props} />
    <img className={s.arrow} src="/images/svg/arrow-down.svg" alt="" />
  </div>
);

export default Select;

import React, { useState } from 'react';
import cn from 'classnames';
import { Collapse } from 'react-collapse';

import s from './accordion.module.scss';

const Accordion = ({ children, title, className }) => {
  const [isOpen, setIsOpen] = useState(false);
  const handleClick = () => setIsOpen(!isOpen);
  const classes = cn(s.accordion, isOpen ? s.open : s.close, className);
  return (
    <div className={classes}>
      <div className={s.title} onClick={handleClick}>
        <h4>{title}</h4>
        <img
          alt="cross"
          src="/images/svg/cross@1x.svg"
          srcSet="/images/svg/cross@1x.svg 1x, /images/svg/cross@2x.svg 2x"
          className={s.indicator}
        />
      </div>
      <Collapse isOpened={isOpen}>
        <div className={s.content}>{children}</div>
      </Collapse>
    </div>
  );
};

export default Accordion;

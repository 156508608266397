import React from 'react';
import cn from 'classnames';
import { Helmet } from 'react-helmet';

import { Container } from 'ui-kit';

const NotFound = ({ className }) => (
  <div className={cn(className)}>
    <Helmet
      title="Not Found Page"
      meta={[
        { name: 'description', content: 'Description for Not Found page.' },
      ]}
    />
    <Container>
      <Container full={false}>Not Found page</Container>
    </Container>
  </div>
);

export default NotFound;

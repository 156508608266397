import React from 'react';
import cn from 'classnames';

import { withTranslation } from 'react-i18next';
import { Container, Input, Button } from 'ui-kit';
import s from './footer.module.scss';

const Footer = ({ t, i18n }) => {
  return (
    <footer className={s.footer}>
      <Container className={s.container}>
        <div className={s.blocks}>
          <div className={s.block} />
          <div className={s.block} />
        </div>
        <Container full={false} className={s.content}>
          <div className={s.section}>
            <h2>Контакты</h2>
            <div className={s.info}>
              <div className={s.infoBlock}>
                <div className={s.title}>Офис издательства</div>
                <div className={s.phone}>(044) 467 50 69</div>
                <div className={s.schedule}>
                  <div>Будние дни: 10:00 - 17:00</div>
                  <div>Звонки, согласно тарифам оператора.</div>
                </div>
              </div>
              <div className={s.infoBlock}>
                <div className={s.title}>Гарячая линия</div>
                <div className={s.phone}>0 (800) 30 20 20</div>
                <div className={s.schedule}>
                  <div>Каждый день: 07:00 - 22:00</div>
                  <div>Бесплатно с мобильных телефонов.</div>
                </div>
              </div>
            </div>
          </div>
          <div className={cn(s.section, s.subscribe)}>
            <div className={s.info}>
              <h2>Подписаться</h2>
              <div className={s.form}>
                <Input
                  type="text"
                  className={s.input}
                  placeholder={`${t('Введите свой E-mail')}...`}
                />
                <Button
                  text={t('Подписаться')}
                  iconRight="arrow-right-white-sm"
                />
              </div>
              <div className={s.notice}>
                *Вы можете отписаться в любое время.
              </div>
            </div>
          </div>
        </Container>
      </Container>
      <Container className={s.container}>
        <Container full={false} className={s.subFooter}>
          <div className={s.rights}>
            @2009 - {new Date().getFullYear()}. Христианское издательство
            "Джерело життя".
          </div>
          <div className={s.socials}>
            <div className={s.social}>
              <img src="/images/svg/social-instagram.svg" alt="" />
            </div>
            <div className={s.social}>
              <img src="/images/svg/social-telegram.svg" alt="" />
            </div>
            <div className={s.social}>
              <img src="/images/svg/social-facebook.svg" alt="" />
            </div>
          </div>
        </Container>
      </Container>
    </footer>
  );
};

export default withTranslation()(Footer);

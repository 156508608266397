import React, { useState, useEffect, useContext } from 'react';
import cn from 'classnames';
import { withTranslation } from 'react-i18next';
import ItemsCarousel from 'react-items-carousel';

import { WidthContext } from 'contexts/width-context';
import { LazyLoadImage } from 'react-lazy-load-image-component';

import { Container, Button, Link } from 'ui-kit';
import s from './slide.module.scss';

const Slider = ({ t, banner, i18n }) => {
  const lang = i18n.language;
  const [currentSlide, setCurrentSlide] = useState(0);
  const [activeItemIndexPhone, setCurrentSlidePhone] = useState(0);

  const { isLapUp, isMob } = useContext(WidthContext);
  function PrevArrow({ currentSlide, slideCount, ...props }) {
    return (
      <div {...props} className={cn(s.left)}>
        <img src="/images/svg/arrow-left-v2.svg" alt="" />
      </div>
    );
  }

  function NextArrow({ currentSlide, slideCount, ...props }) {
    return (
      <div {...props} className={cn(s.right)}>
        <img src="/images/svg/arrow-left-v2.svg" alt="" />
      </div>
    );
  }

  const color = banner?.[currentSlide]?.backgroundColor.slice(4, -1);
  const bgMain = `linear-gradient(135deg, rgba(${color}, 0.03) 20%, rgba(${color}, 0.2) 100%)`;

  const [bgs, setBgs] = useState({
    bg1: bgMain,
    bg2: bgMain,
  });
  useEffect(() => {
    setBgs({
      bg1: bgMain,
    });
  }, [bgMain]);

  return (
    <>
      {isLapUp && (
        <>
          <div
            style={{ background: bgs.bg1 }}
            className={cn(s.sliderBg, s.active)}
          />
          <div style={{ background: bgs.bg2 }} className={cn(s.sliderBg)} />
        </>
      )}

      <ItemsCarousel
        classes={{
          wrapper: cn(s.slider, s.loaded),
          rightChevronWrapper: cn(s.arrow, s.right),
          leftChevronWrapper: cn(s.arrow, s.left),
        }}
        alwaysShowChevrons
        requestToChangeActive={(nextItemIndex) => {
          if (isLapUp) {
            nextItemIndex < 0 ? (nextItemIndex = 0) : null;

            nextItemIndex > banner.length - 1 ? (nextItemIndex = 0) : null;

            setCurrentSlide(nextItemIndex);
            return;
          }
          nextItemIndex < 0 ? (nextItemIndex = 0) : null;
          nextItemIndex > banner.length - 1 ? (nextItemIndex = 0) : null;

          setCurrentSlidePhone(nextItemIndex);
        }}
        activeItemIndex={isLapUp ? currentSlide : activeItemIndexPhone}
        numberOfCards={1}
        leftChevron={<PrevArrow />}
        rightChevron={<NextArrow />}
      >
        {banner.map(
          ({ description, version, backgroundColor, buttonTextColor }, key) => {
            const color = backgroundColor.slice(4, -1);
            const { authors, title, id, info, year, img } = version;

            let bookAuthors;
            if (authors.length > 1) {
              bookAuthors = (
                <div>
                  <span className={s.authorName}>
                    {`${authors[0].firstName[lang]} ${authors[0].lastName[lang]},
                    ... `}
                  </span>
                  <span
                    className={s.authorsNumber}
                    style={{ border: `1px solid rgba(${color}, 0.5` }}
                  >
                    +{authors.length - 1}
                  </span>
                </div>
              );
            } else {
              bookAuthors = (
                <span className={s.authorName}>
                  {`${authors[0].firstName[lang]} ${authors[0].lastName[lang]}`}
                </span>
              );
            }

            return (
              <div key={key}>
                <div className={cn(s.slide, s.touchAction)}>
                  <Container full={false} className={s.slideContainer}>
                    <div className={s.mainInfo}>
                      <div className={s.author}>
                        <div className={s.name}>{bookAuthors}</div>
                      </div>
                      <h1 className={s.bookName}>{title}</h1>
                      {!isMob && <p className={s.description}>{description}</p>}
                      {isMob ? (
                        <Link
                          to={`/books/${id}`}
                          className={s.link}
                          style={{ color: `rgb(${color}` }}
                        >
                          Подробнее
                          <svg
                            width="37px"
                            height="17px"
                            viewBox="0 0 37 17"
                            version="1.1"
                            xmlns="http://www.w3.org/2000/svg"
                          >
                            <g id="Page-1" stroke="none" strokeWidth="1">
                              <g
                                id="main-page-(banner-variant)-copy"
                                transform="translate(-448.000000, -1229.000000)"
                                fill={`rgb(${color}`}
                                fillRule="nonzero"
                              >
                                <path
                                  d="M476.034424,1229.23223 L484.267767,1237.46558 L475.974854,1245.75849 L474.207087,1243.99072 L479.482,1238.71523 L448.75,1238.71558 L448.75,1236.21558 L479.482,1236.21523 L474.266657,1231 L476.034424,1229.23223 Z"
                                  id="Combined-Shape"
                                  transform="translate(466.508883, 1237.495361) scale(-1, 1) translate(-466.508883, -1237.495361) "
                                />
                              </g>
                            </g>
                          </svg>
                        </Link>
                      ) : (
                        <Button
                          to={`/books/${id}`}
                          className={s.readMore}
                          children="Подробнее"
                          style={{
                            backgroundColor: `rgba(${color}, 0.2)`,
                          }}
                        />
                      )}
                    </div>
                    <div className={s.book}>
                      {!isMob && (
                        <div className={s.blurImage}>
                          <div
                            style={{ backgroundColor: `rgba(${color}, 0.18)` }}
                          />
                        </div>
                      )}
                      <div className={s.image}>
                        <LazyLoadImage
                          className={s.front}
                          src={img}
                          alt={title}
                        />
                        <LazyLoadImage
                          className={s.back}
                          src={img}
                          alt={title}
                        />
                        {/* <img className={s.front} src={img} alt={title} />
                      <img className={s.back} src={img} alt={title} /> */}
                        <LazyLoadImage
                          className={s.shadow}
                          alt={title}
                          style={{
                            backgroundImage: `url(${img})`,
                            backgroundColor: `rgba(${color}, 1)`,
                          }}
                        />
                        {/* <img
                        className={s.shadow}
                        alt={title}
                        style={{
                          backgroundImage: `url(${img})`,
                          backgroundColor: `rgba(${color}, 1)`
                        }}
                      /> */}
                      </div>
                    </div>
                    {isLapUp && (
                      <div className={s.info}>
                        {!!info.binding && (
                          <div className={s.prop}>
                            <div className={s.key}>Переплет:</div>
                            <div className={s.value}>{t(info.binding)}</div>
                          </div>
                        )}
                        {!!info.pages && (
                          <div className={s.prop}>
                            <div className={s.key}>Страниц:</div>
                            <div className={s.value}>{info.pages}</div>
                          </div>
                        )}
                        {!!year && (
                          <div className={s.prop}>
                            <div className={s.key}>Год:</div>
                            <div className={s.value}>{year}</div>
                          </div>
                        )}
                        {!!info.copies && (
                          <div className={s.prop}>
                            <div className={s.key}>Тираж:</div>
                            <div className={s.value}>{info.copies}</div>
                          </div>
                        )}
                      </div>
                    )}
                    <div className={s.invisible} />
                  </Container>
                  {isLapUp && <div className={s.invisible} />}
                </div>
              </div>
            );
          }
        )}
      </ItemsCarousel>

      <ul className={s.thumb}>
        {banner.map((item, i) => (
          <li
            key={i}
            className={
              isLapUp
                ? i === currentSlide
                  ? 'active'
                  : null
                : i === activeItemIndexPhone
                ? 'active'
                : null
            }
            onClick={() => {
              setCurrentSlide(i);
            }}
          >
            <div
              className={s.item}
              style={{ border: `2px solid ${banner[i].backgroundColor}` }}
            />
          </li>
        ))}
      </ul>
    </>
  );
};

export default withTranslation()(Slider);

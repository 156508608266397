import React, { cloneElement, Children } from 'react';

import cn from 'classnames';
import s from './tabs.module.scss';

const TabsPanel = ({
  active,
  className,
  changeActiveTab,
  disabled,
  ...props
}) => {
  const childrens = Children.toArray(props.children);
  const classNames = cn(s.tabsPanel, className);
  return (
    <div className={classNames} {...props}>
      {childrens.map((children, index) =>
        cloneElement(children, {
          isActive: index === active,
          tabIndex: index,
          changeActiveTab: (tabIndex) => changeActiveTab(tabIndex),
        })
      )}
    </div>
  );
};

export default TabsPanel;

import React, { useContext } from 'react';
import cn from 'classnames';
import { withTranslation } from 'react-i18next';
import { WidthContext } from 'contexts/width-context';
import { imageUrl } from 'utils';
import { LazyLoadImage } from 'react-lazy-load-image-component';
import { Link } from 'ui-kit';
import s from './issue.module.scss';

const Issue = ({
  id,
  img,
  info,
  match,
  title,
  publishYear,
  isAnimate,
  categories,
  description,
  periodical,
  periodicalId,
  scrollPosition,
  versions,
  numberTotal,
  thumbnail,
  className,
  w,
  h,
  i18n,
  t,
}) => {
  const { isRetina } = useContext(WidthContext);
  const DEFAUTL_BOOK_COVER = '/images/svg/defaultBook.svg';
  const image = thumbnail;
  let coefficient = 1;
  const DEFAULT_WIDTH = 180;
  const DEFAULT_HEIGHT = 244;

  const imageSrc =
    (isRetina ? imageUrl(image, '340@2x') : imageUrl(image, '350')) ||
    DEFAUTL_BOOK_COVER;

  const placeholderSrc =
    imageUrl(image, '70') || '/images/svg/lazyLoadBook.svg';

  if (w || h) {
    if (w) coefficient = DEFAULT_WIDTH / w;
    if (h) coefficient = DEFAULT_HEIGHT / h;
  }

  const width = Math.ceil(w || DEFAULT_WIDTH / coefficient);
  const height = Math.ceil(h || DEFAULT_HEIGHT / coefficient);
  return (
    <Link
      title={title}
      to={`/periodicals/${periodical.id}/${id}`}
      style={{ width: `${width}px` }}
      className={cn(s.issue, isAnimate && s.animate, className)}
    >
      <div className={s.image} style={{ height: `${height}px` }}>
        <LazyLoadImage
          alt={title}
          effect="blur"
          src={imageSrc}
          className={s.front}
          wrapperClassName={s.front}
          scrollPosition={scrollPosition}
          placeholderSrc={placeholderSrc}
          onError={({ currentTarget }) => {
            currentTarget.src = DEFAUTL_BOOK_COVER;
          }}
        />
        <LazyLoadImage
          alt={title}
          src={imageSrc}
          className={s.back}
          wrapperClassName={s.back}
          scrollPosition={scrollPosition}
          placeholderSrc={placeholderSrc}
          onError={({ currentTarget }) => {
            currentTarget.src = DEFAUTL_BOOK_COVER;
          }}
        />

        <div className={s.shadow} />
      </div>
      <h3 className={s.name} title={title}>
        {`${title} (${numberTotal}) ${publishYear}`}
      </h3>
      <div className={s.type}>{t(periodical.type)}</div>
    </Link>
  );
};

export default withTranslation()(Issue);

const capitalize = (s) =>
  s.charAt(0).toUpperCase() + s.substring(1).toLowerCase();

const getSearchParams = (search) => {
  const params = {};
  search
    .replace('?', '')
    .split('&')
    .forEach((param) => {
      const key = param.split('=')[0];
      const value = param.split('=')[1];
      params[key] = +value;
    });
  return params;
};

const imageUrl = (url, size, format = 'jpeg') => {
  if (url) {
    const split = url.split('.');

    if (format === 'jpeg') {
      split[split.length - 2] = split[split.length - 2].replace(
        'public_cover',
        'thumbnail'
      );
    }

    split[split.length - 2] += `-${size}`;
    split[split.length - 1] = format;

    return split.join('.');
  }

  return null;
};

export { capitalize, getSearchParams, imageUrl };
export * from './axios';

import React, { useEffect } from 'react';
import cn from 'classnames';
import { Helmet } from 'react-helmet';
import { withTranslation } from 'react-i18next';
import { useDataApi } from 'components';
import { Breadcrumbs, Container, Spin } from 'ui-kit';
import {
  IssueDetail,
  SimularIssues,
  IssueInformation,
  OtherPeriodicals,
} from './components';
import s from './issue.module.scss';

const Issue = ({ i18n, match, className, scrollPosition, ...props }) => {
  const { id, issueId } = match.params;

  const [
    { data: issue, isLoading: isLoadingIssue },
    getIssue,
  ] = useDataApi.getIssue({
    id,
    issueId,
  });

  useEffect(() => {
    getIssue({
      id,
      issueId,
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [id, issueId]);

  const [
    {
      data: { periodicals },
      isLoading: isLoadingPeriodicals,
    },
  ] = useDataApi.getPeriodicals({
    public: true,
    offset: 0,
    limit: 5,
  });

  const [
    {
      data: { issues },
      isLoading: isLoadingIssues,
    },
  ] = useDataApi.getPeriodicalIssues({
    public: true,
    offset: 0,
    limit: 5,
    id,
    issueId,
  });

  if (isClient) {
    localStorage?.setItem('publication', JSON.stringify(issue));
  }

  const switchModal = (url) => {};

  return isLoadingIssue && isLoadingIssues && isLoadingPeriodicals ? (
    <Spin />
  ) : (
    <div className={cn(className, s.issue)}>
      <Helmet
        title="Issue Page"
        meta={[{ name: 'description', content: 'Description for Issue page.' }]}
      />
      <Container>
        <Container full={false}>
          <Breadcrumbs
            paths={[
              {
                title: 'periodicals',
                path: '/periodicals',
              },
              {
                title: issue?.periodical?.title,
                path: `/${issue?.periodical?.uid}`,
              },
              {
                title: `${issue?.title} (${issue?.numberTotal}) ${issue?.publishYear}`,
                path: issue?.id,
              },
            ]}
          />
          <IssueDetail {...{ issue, switchModal, scrollPosition }} />
          <SimularIssues {...{ issue, issues, match, scrollPosition }} />
          <IssueInformation {...{ issue }} />
          <OtherPeriodicals {...{ periodicals, match, scrollPosition }} />
        </Container>
      </Container>
    </div>
  );
};

export default withTranslation()(Issue);

const ru = {
  lifesource: 'Джерело життя',
  'christian-publishing': 'христианское издательство',
  Home: 'Главная',
  Books: 'Книги',
  books: 'книги',
  'new books': 'новые книги',
  periodicals: 'периодика',
  Periodicals: 'Периодика',
  contacts: 'контакты',
  authors: 'авторы',
  author: 'автор',
  'Home page': 'Главная страница',
  Authors: 'Авторы',
  Contacts: 'Контакты',
  magazine: 'журнал',
  newspaper: 'газета',
  freq: 'периодичность',
  copies: 'тираж',
  monthly: 'ежемесячный',
  Monthly: 'ежемесячный',
  quarterly: 'ежеквартальный',
  Quarterly: 'ежеквартальный',
  bimonthly: '1 раз в 2 месяца',
  Bimonthly: '1 раз в 2 месяца',
  semiannually: '1 раз в полгода',
  Semiannually: '1 раз в полгода',
  annually: 'ежегодный',
  Annually: 'ежегодный',
  'on bracket': 'скоба',
  soft: 'мягкий',
  hard: 'твердый',
  search: 'Найти',
  'search results': 'результаты поиска',
  'no matches for': 'нет совпадений для',
  paper: 'бумажная',
  'all-issues': 'Все выпуски',
  categories: 'категории',
  'recommended price': 'рекомендованная цена',
  uah: 'грн',
  'cover type': 'переплет',
  format: 'формат',
  mm: 'мм',
  evg: 'евангелист',
  writer: 'писатель',
  'read all': 'Читати всю книгу',
  'read fragment': 'Читать фрагмент',
  'read issue': 'Читать выпуск',
  'where to buy': 'где купить',
  'download book for free': 'скачать книгу бесплатно',
  'home.about1':
    'Издательство «Джерело життя» основано в 1994 году и является одним из первых в Украине издательств, выпускающих христианскую литературу. На сегодняшний день это широкий ассортимент изданий на украинском и русском языках для всех возрастных групп: детей, подростков, молодежи, взрослых.',
  'home.about2':
    'Наша цель – через печатное слово распространять духовные ценности, принципы здорового образа жизни и семейного благополучия, нести надежду, вдохновлять, учить, побуждать к добру и познанию истины, ведь только истина может сделать человека по-настоящему свободным и счастливым.',
  'home.accordion.title1': 'Наши принципы',
  'home.accordion.text1.1':
    'Мы исповедуем Триединого Бога как Творца и Искупителя человечества, соблюдаем принципы Десятисловного Закона, включая четвертую заповедь о субботе, и ожидаем скорого Второго пришествия Христа на Землю.',
  'home.accordion.text1.2':
    'Мы исповедуем чистоту семейных отношений, которые исключают интимную связь до брака и вне его и по Божьему замыслу предусматривают духовное, эмоциональное и физическое единение мужчины и женщины в атмосфере взаимопонимания, уважения и христианской любви.',
  'home.accordion.text1.3':
    'Мы исповедуем здоровый образ жизни, основанный на восьми принципах здоровья: сбалансированное питание (употребление чистой пищи, согласно Левит 11), достаточная физическая активность, полноценный отдых, использование воды и солнечного света, правильное дыхание, отказ от вредных привычек и всецелое доверие Богу.',
  'home.accordion.text1.4':
    'Мы исповедуем моральные ценности, которые зиждутся на библейских принципах и истинах, открытых в Духе пророчества. Это гармоничные отношения с Творцом и людьми, честность во всех сферах жизни, саморазвитие и постоянный духовный рост.',
  'home.accordion.title2': 'Тематика книг',
  'home.accordion.text2.1':
    'Мы издаем книги украинских и зарубежных авторов, которые верят в Бога и силу печатного слова. Это научные сотрудники, публицисты, христианские писатели.',
  'home.accordion.text2.2':
    'Все издания можно разделить на следующие тематические группы:',
  'home.accordion.text2.3':
    'книги о духовных и нравственных ценностях: как обрести смысл жизни, как познать характер Бога, как усовершенствовать себя, как быть устойчивым в жизненных испытаниях, как развиваться духовно;',
  'home.accordion.text2.4':
    'книги о здоровом образе жизни: лечение и профилактика распространенных заболеваний, природные средства лечения, вегетарианское питание, методы преодоления стресса и обретения душевного равновесия, секреты долголетия;',
  'home.accordion.text2.5':
    'книги по семейной тематике: як создать счастливую христианскую семью, как решать конфликты, как справиться с гневом, как преодолеть трудности в браке, как сделать супруга счастливым;',
  'home.accordion.text2.6':
    'книги по  воспитанию детей: как воспитать послушных детей, каковы секреты счастливого отцовства, как справиться с проблемами подросткового возраста, как привить ребенку интерес к духовным истинам, в чем суть христианских принципов воспитания;',
  'home.accordion.text2.7':
    'детская литература: раскраски, христианские рассказы для самых маленьких, английские азбуки, приключенческие истории, развивающие книги;',
  'home.accordion.text2.8':
    'книги для молодежи: как найти надежных друзей, как строить отношения, как выбрать спутника жизни, каковы секреты достижения успеха, как благотворно влиять на людей;',
  'home.accordion.text2.9':
    'художественная литература: жизненные истории христиан, автобиографии интересных людей, путь к успеху через испытания.',
  'home.accordion.text2.10':
    'Это книги со смыслом, которые стоит читать каждому. Они способны обогатить духовно, ободрить физически, насытить интеллектуально.',
  'home.accordion.title3': 'Периодика',
  'home.accordion.text3.1':
    'Мы ежемесячно предлагаем своим читателям новую порцию актуальной и интересной информации для углубления духовного мировоззрения. Наши периодические издания проходят профессиональную экспертизу научных сотрудников и содержат проверенную информацию из надежных источников. Среди наших авторов – доктора и кандидаты наук, публицисты, поэты и рядовые читатели.',
  'home.accordion.text3.2': 'Наши газеты:',
  'home.accordion.text3.3':
    'газета о духовных ценностях «Вечное Сокровище» / «Вічний Скарб»',
  'home.accordion.text3.4': 'газета о здоровье «Моя здорова сім’я»',
  'home.accordion.text3.5': 'детская христианская газета «Скарбничка»',
  'home.accordion.text3.6': 'молодежная христианская газета «Флешка»',
  'home.accordion.text3.7': 'внутрицерковная газета «Адвентистский вестник»',
  'home.accordion.text3.8': 'Наши журналы:',
  'home.accordion.text3.9': 'женский журнал «Маргарита»',
  'home.accordion.text3.10': 'общественно-научный журнал «Знамения времени»',
  'home.accordion.text3.11': 'детский журнал о путешествиях «Дружболандия»',
  'home.accordion.text3.12': 'подростковый карманный журнал «Скайфон»',
  'home.accordion.title4': 'Наши бестселлеры',
  'home.accordion.text4.1':
    'Серия «Конфликт веков»: «Патриархи и пророки», «Пророки и цари», «Христос – надежда мира» («Желание веков»), «Деяния апостолов», «Великая борьба».',
  'home.accordion.text4.2':
    'Автор данной серии книг – известная христианская писательница Эллен Уайт (1827-1915). В этих пяти томах изложен весь ход истории человечества от начала и до скорого завершения, описывается великая борьба между добром и злом, План спасения, происхождение, развитие и уничтожение зла.',
  'home.accordion.text4.3': '«Золотые руки» Бен Карсон.',
  'home.accordion.text4.4':
    'Путь к успеху от безнадежного двоечника в школе до всемирно известного врача, на счету которого десятки уникальных операций. От неудачника до человека, добившегося мирового признания. Как это возможно? Автор книги не делает из этого секрета, а охотно делится своим рецептом успеха с другими.',
  'home.accordion.text4.5': '«Депрессия: как ее победить?» Нил Недли.',
  'home.accordion.text4.6':
    'Эта книга полезна не только тем, кто страдает от депрессии, но и всем, чья жизнь связанна с частыми стрессами, кто время от времени испытывает приступы тревожности, плохого настроения и упадка сил. Ее автор на основании современных исследований указывает пути предупреждения и преодоления данных психических расстройств: это и физические упражнения, и работа над мышлением, и изменение рациона питания.',
  'home.accordion.text4.7':
    '«Секреты здоровья и долголетия» Алексей Хацинский.',
  'home.accordion.text4.8':
    'В книге содержатся 365 здоровых советов на каждый день. Если вы желаете сохранить или восстановить свое здоровье, тогда вам нужно ежедневно делать один шаг ему навстречу. Изменения к лучшему не заставят себя долго ждать, и вы соберете щедрый урожай здоровья.',
  'home.accordion.text4.9': '«Отважьтесь просить о большем» Мелоди Мейсон.',
  'home.accordion.text4.10':
    'Эта книга оживит и упорядочит вашу молитвенную жизнь, а также станет эффективным практическим пособием для молитвенных служений. Автор призывает нас просить о большем, ведь «молитва – это ключ в руках веры, открывающий небесную сокровищницу». Мы будем чаще склоняться на колени, если осознаем истинную силу молитвы. А чем для вас является молитва – рулем или аварийным тормозом?.',
};

export default ru;

import React, { useContext, Fragment } from 'react';
import cn from 'classnames';
import { withTranslation } from 'react-i18next';
import { WidthContext } from 'contexts/width-context';
import { imageUrl } from 'utils';
import { Link } from 'ui-kit';
import { LazyLoadImage } from 'react-lazy-load-image-component';
import s from './book-detail.module.scss';
import bookStyles from '../book.module.scss';
import BookTabs from './book-tabs';

function BookDetail({
  t,
  i18n,
  book,
  switchModal,
  scrollPosition,
  versionNumber,
}) {
  const lang = i18n.language;
  const {
    publicLayoutFull,
    publicLayout,
    eBooks = [],
    title,
    price,
    authors = [],
    annotation,
    versions = [],
    categories = [],
    uid,
  } = book;
  const { isTab, isRetina } = useContext(WidthContext);
  const bindings = [];
  const sizes = [];
  let size;
  const DEFAUTL_BOOK_COVER = '/images/svg/defaultBook.svg';

  versions.forEach(({ info: { binding, width, height } }, i) => {
    if (width && height) {
      size = `${width}x${height}`;
    }

    if (binding && size) {
      bindings[binding]
        ? bindings[binding].push(size)
        : (bindings[binding] = [size]);

      sizes[size] ? sizes[size].push(binding) : (sizes[size] = [binding]);
    }
  });

  const thumb = versions.map((item, i) => {
    const count = versions.length - 1 - i;
    return (
      <Link title={title} to={`/books/${uid}.${count + 1}`}>
        <li className={count === versionNumber - 1 ? s.slickActive : ''}>
          <div className={s.item}>
            <div className={s.image}>
              <img
                src={imageUrl(versions?.[count]?.img, '70', 'jpeg')}
                alt=""
              />
            </div>
          </div>
        </li>
      </Link>
    );
  });

  const detail = versions.map(({ id, img }, i) => {
    const imageSrc =
      (isRetina ? imageUrl(img, '700@2x') : img) || DEFAUTL_BOOK_COVER;

    const placeholderSrc =
      imageUrl(img, '70') || '/images/svg/lazyLoadBook.svg';

    return (
      <div className={s.slider}>
        <div key={id} className={s.slide}>
          <div>
            <div className={s.book}>
              <div className={s.image} onClick={() => switchModal(img)}>
                <LazyLoadImage
                  alt=""
                  src={imageSrc}
                  className={s.front}
                  wrapperClassName={s.frontW}
                  scrollPosition={scrollPosition}
                  placeholderSrc={placeholderSrc}
                  onError={({ currentTarget }) => {
                    currentTarget.src = DEFAUTL_BOOK_COVER;
                  }}
                />

                <LazyLoadImage
                  alt=""
                  src={imageSrc}
                  className={s.back}
                  wrapperClassName={s.backW}
                  scrollPosition={scrollPosition}
                  placeholderSrc={placeholderSrc}
                  onError={({ currentTarget }) => {
                    currentTarget.src = DEFAUTL_BOOK_COVER;
                  }}
                />

                <div
                  className={s.shadow}
                  style={{
                    backgroundImage: `url(${img})`,
                  }}
                />
              </div>
            </div>
            {(publicLayout || publicLayoutFull) && (
              <div className={s.actions}>
                <a
                  target="__blank"
                  href={publicLayoutFull || publicLayout}
                  className={s.pdf}
                >
                  <img src="/images/svg/pdf-read-blue.svg" alt="" />
                  <span>
                    {publicLayoutFull ? t('read all book') : t('read fragment')}
                  </span>
                </a>
                {/* <button
                onClick={() => { dowloadFile(publicLayoutFull.length ? publicLayoutFull : publicLayout) }}
                className="slider__slide__actions__pdf"
                >
                <img src={pdfIcon} alt="" />
                Читать фрагмент
            </button> */}
              </div>
            )}
          </div>

          <div className={s.description}>
            <div className={s.bookName}>{title}</div>
            {authors.length > 0 && (
              <div className={s.bookProp}>
                <span className={s.propName}>
                  {authors.length > 1 ? t('authors') : t('author')}:
                </span>
                {authors.map(({ id, firstName, lastName }, index) => (
                  <Fragment key={id}>
                    <Link data-v1 to={`/authors/${id}`}>
                      {firstName[lang]} {lastName[lang]}
                    </Link>

                    {authors.length > index + 1 && (
                      <span className={s.comma}>, &nbsp;</span>
                    )}
                  </Fragment>
                ))}
              </div>
            )}
            <div className={s.annotation}>{annotation}</div>
            {categories.length > 0 && (
              <div className={cn(s.bookProp, s.categories)}>
                <span className={s.propName}>{t('category')}: </span>
                {categories.map(({ title }, index) => (
                  <Link data-v1 to="#" key={id}>
                    {title}
                  </Link>
                ))}
              </div>
            )}
            {!isTab && (
              <BookTabs
                {...{ bindings, sizes, versions, eBooks, price, versionNumber }}
              />
            )}
          </div>
          {isTab && (
            <BookTabs
              {...{ bindings, sizes, versions, eBooks, price, versionNumber }}
            />
          )}
        </div>

        <ul className={s.thumb}>{thumb}</ul>
      </div>
    );
  });

  return (
    <div className={cn(bookStyles.block, s.detail)}>
      {detail[versionNumber - 1]}

      <div className={s.sliderItem} />
    </div>
  );
}

export default withTranslation()(BookDetail);

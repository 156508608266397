import React, { useContext, Fragment, useEffect } from 'react';
import cn from 'classnames';
import Slider from 'react-slick';
import { withTranslation } from 'react-i18next';
import { useDataApi, Book } from 'components';
import { WidthContext } from 'contexts/width-context';
import { Link, MainTitle } from 'ui-kit';
import { LazyLoadImage } from 'react-lazy-load-image-component';
import s from '../book.module.scss';

function BookAuthor({ i18n, book, match, scrollPosition }) {
  const lang = i18n.language;
  const { authors } = book;
  const urlBooksOfAuthor = `/books?limit=4&offset=0&f.authors=${authors[0]?.id}&versions=true`;
  const [{ data }, getBooks] = useDataApi.useDataApi(urlBooksOfAuthor, {
    books: {},
  });

  useEffect(() => {
    getBooks(urlBooksOfAuthor);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [urlBooksOfAuthor]);

  const { books } = data;
  const { isTabDown } = useContext(WidthContext);

  function PrevArrow({ currentSlide, slideCount, ...props }) {
    return (
      <div className={cn(s.arrow, s.left)}>
        <img {...props} src="/images/svg/arrow-left-v2.svg" alt="" />
      </div>
    );
  }

  function NextArrow({ currentSlide, slideCount, ...props }) {
    return (
      <div className={cn(s.arrow, s.right)}>
        <img {...props} src="/images/svg/arrow-left-v2.svg" alt="" />
      </div>
    );
  }

  const settings = {
    className: s.slider,
    slidesToScroll: 1,
    arrows: isTabDown,
    dots: true,
    fade: true,
    speed: 200,
    dotsClass: s.thumb,
    nextArrow: <NextArrow />,
    prevArrow: <PrevArrow />,
    customPaging: (i) => {
      const authoImageUrl = authors[i].photo
        ? `url(${authors[i].photo})`
        : 'url(/images/default-author.png)';
      return !isTabDown ? (
        <div
          className={s.item}
          style={{
            backgroundImage: authoImageUrl,
            backgroundPosition: 'center',
            backgroundSize: 'contain',
          }}
        />
      ) : (
        <div className={s.item}>
          {!isTabDown && (
            <img
              src={authors[i].photo || '/images/default-author.png'}
              alt=""
            />
          )}
        </div>
      );
    },
  };

  return (
    <div className={cn(s.block, s.author)}>
      <div className={s.head}>
        <MainTitle
          className={s.mainTitle}
          texts={['Информация', 'об авторе']}
        />
      </div>
      <Slider {...settings}>
        {authors.map(
          ({ id, photo, firstName, lastName, type, description }) => (
            <Fragment key={id}>
              <div className={s.info}>
                {!!photo.length && (
                  <LazyLoadImage
                    alt=""
                    className={s.photo}
                    wrapperClassName={s.photoW}
                    scrollPosition={scrollPosition}
                    placeholderSrc="/images/default-author.png"
                    src={photo || '/images/default-author.png'}
                  />
                )}
                <div className={s.content}>
                  <div className={s.name}>
                    {firstName[lang]} {lastName[lang]}
                  </div>
                  <div className={s.type}>
                    {type === 'evg' ? 'Evangelist' : type}
                  </div>
                  <div className={s.description}>{description}</div>
                  {books.length && (
                    <Link data-v2 to={`/authors/${id}`} className={s.allBooks}>
                      показать все книги автора ({books.length})
                      <img src="/images/svg/arrow-left-v2.svg" alt="" />
                    </Link>
                  )}
                </div>
              </div>
              {books.length && (
                <div className={s.books}>
                  {books.map((item, key) => (
                    <Book
                      {...item}
                      key={key}
                      match={match}
                      className={s.book}
                      w={isTabDown ? 150 : null}
                      scrollPosition={scrollPosition}
                    />
                  ))}
                </div>
              )}
            </Fragment>
          )
        )}
      </Slider>
    </div>
  );
}

export default withTranslation()(BookAuthor);

import React from 'react';
import cn from 'classnames';
import { Link as RouterLink } from 'react-router-dom';

import { withTranslation } from 'react-i18next';
import s from './link.module.scss';

const Link = ({ tReady, t, to, className, i18n, ...props }) => {
  const { language } = i18n;
  const DEFAULT_LANG = 'ru';
  const isToObject = typeof to == 'object';
  const pathname = isToObject ? to.pathname : to;
  const isOther = /http/.test(pathname);
  const Link = isOther ? 'a' : RouterLink;
  const url =
    language === DEFAULT_LANG
      ? pathname
      : `/${language}${pathname.replace(`/${language}`, '')}`;

  return (
    <Link
      {...(isOther ? { rel: 'noreferrer' } : {})}
      {...props}
      href={to}
      to={url}
      className={cn(s.link, className)}
    />
  );
};

export default withTranslation()(Link);

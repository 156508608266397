import React, { useContext } from 'react';
import cn from 'classnames';
import { withTranslation } from 'react-i18next';
import { Link } from 'ui-kit';
import { imageUrl } from 'utils';
import { LazyLoadImage } from 'react-lazy-load-image-component';
import { WidthContext } from 'contexts/width-context';
import s from './new-book.module.scss';

const NewBook = ({
  id,
  img = '/images/svg/defaultBook.svg',
  info,
  match,
  title,
  authors,
  isAnimate,
  categories,
  description,
  scrollPosition,
  versions,
  thumbnail,
  w,
  h,
  i18n,
}) => {
  const lang = i18n.language;
  const image = thumbnail;
  let coefficient = 1;
  const DEFAULT_WIDTH = 274;
  const DEFAULT_HEIGHT = 288;
  const { isRetina } = useContext(WidthContext);
  const DEFAUTL_BOOK_COVER = '/images/svg/defaultBook.svg';

  const imageSrc =
    (isRetina ? imageUrl(image, '340@2x') : imageUrl(image, '350')) ||
    DEFAUTL_BOOK_COVER;

  const placeholderSrc =
    imageUrl(image, '70') || '/images/svg/lazyLoadBook.svg';

  if (w || h) {
    if (w) coefficient = DEFAULT_WIDTH / w;
    if (h) coefficient = DEFAULT_HEIGHT / h;
  }

  const width = Math.ceil(w || DEFAULT_WIDTH / coefficient);
  const height = Math.ceil(h || DEFAULT_HEIGHT / coefficient);

  const bookAuthors = authors
    .map(({ firstName, lastName }) => `${firstName[lang]} ${lastName[lang]}`)
    .join(',');

  return (
    <Link
      title={title}
      to={`/books/${id}`}
      style={{ width: `${width}px` }}
      className={cn(s.book, isAnimate && s.animate)}
    >
      <div className={s.shadow}>
        <LazyLoadImage src={placeholderSrc} alt={title} />
      </div>
      <div className={s.image} style={{ height: `${height}px` }}>
        <LazyLoadImage
          alt={title}
          effect="blur"
          src={imageSrc}
          className={s.front}
          wrapperClassName={s.frontW}
          scrollPosition={scrollPosition}
          placeholderSrc={placeholderSrc}
          onError={({ currentTarget }) => {
            currentTarget.src = DEFAUTL_BOOK_COVER;
          }}
        />
        <LazyLoadImage
          alt={title}
          src={imageSrc}
          className={s.back}
          wrapperClassName={s.backW}
          scrollPosition={scrollPosition}
          placeholderSrc={placeholderSrc}
          onError={({ currentTarget }) => {
            currentTarget.src = DEFAUTL_BOOK_COVER;
          }}
        />

        <div
          className={s.shadow}
          // style={{
          // backgroundImage: `url(${img})`,
          // height: `${height * 0.85}px`,
          // width: `${width * 0.65}px`,
          // }}
        />
      </div>
      <h3 className={s.name} title={title}>
        {title}
      </h3>
      <h4 className={s.author} title={bookAuthors}>
        {bookAuthors}
      </h4>
    </Link>
  );
};

export default withTranslation()(NewBook);

import React, { createContext, useState, useEffect } from 'react';
import isRetinaDisplay from 'is-retina';

export const WidthContext = createContext(null);

export const WidthProvider = ({ children }) => {
  let context = {};
  if (isClient) {
    const [width, setWidth] = useState(window.innerWidth);
    const [retina, setRetina] = useState(isRetinaDisplay());

    useEffect(() => {
      const handleResize = () => setWidth(window.innerWidth);
      window.addEventListener('resize', handleResize);

      setRetina(isRetinaDisplay());

      return () => {
        window.removeEventListener('resize', handleResize);
      };
    }, []);

    const MAX_MOBILE = 639;
    const MAX_TABLET = 1024;
    const MAX_LAPTOP = 1279;
    const isMob = MAX_MOBILE >= width;
    const isTab = MAX_MOBILE < width && width <= MAX_TABLET;
    const isTabUp = MAX_MOBILE < width;
    const isTabDown = MAX_TABLET >= width;
    const isLap = MAX_TABLET < width && width <= MAX_LAPTOP;
    const isLapUp = MAX_TABLET < width;
    const isLapDown = MAX_LAPTOP >= width;
    const isDesk = MAX_LAPTOP < width;
    const isRetina = retina;

    context = {
      width,
      isMob,
      isTab,
      isTabUp,
      isTabDown,
      isLap,
      isLapUp,
      isLapDown,
      isDesk,
      isRetina,
    };
  }

  return (
    <WidthContext.Provider value={isClient ? context : {}}>
      {children}
    </WidthContext.Provider>
  );
};

import React from 'react';
import cn from 'classnames';

import s from './input.module.scss';

const Input = ({
  label,
  icon,
  className,
  placeholder,
  type = 'text',
  iconPos = 'left',
  disabled = false,
  children,
  ...props
}) => {
  return (
    <div
      className={cn(s.input, className, icon && s.withIcon, icon && s[iconPos])}
    >
      {icon && (
        <label>
          <img
            className={s.icon}
            src={`/images/svg/${icon}.svg`}
            alt="search icon"
          />
        </label>
      )}
      <input
        type={type}
        placeholder={placeholder}
        disabled={disabled}
        {...props}
      />
      {children}
    </div>
  );
};

export default Input;

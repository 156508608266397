import React, { useEffect, useState } from 'react';
import cn from 'classnames';
import { Helmet } from 'react-helmet';
import { withTranslation } from 'react-i18next';
import { Author, useDataApi } from 'components';
import { getSearchParams } from 'utils';
import { Breadcrumbs, Container, Spin, Pagination } from 'ui-kit';
import s from './authors.module.scss';

const Authors = ({ t, match, className, location, scrollPosition }) => {
  const params = getSearchParams(location.search);

  const onPage = 12;
  const [page, setPage] = useState(params.page || 1);
  const url = `/authors?limit=${onPage}&offset=${
    onPage * (page - 1)
  }&versions=true`;
  const [{ data, isLoading }, dofetch] = useDataApi.useDataApi('/authors', {
    authors: [],
  });
  const { authors = [], meta } = data;

  useEffect(() => {
    dofetch(url);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [url]);

  useEffect(() => {
    if (params.page) setPage(params.page);
  }, [params.page]);

  return (
    <div className={cn(className, s.authors)}>
      <Helmet
        title="Authors Page"
        meta={[
          { name: 'description', content: 'Description for Authors page.' },
        ]}
      />
      <Container>
        <Container full={false}>
          <Breadcrumbs
            paths={[
              {
                title: 'authors',
                path: '/authors',
              },
            ]}
          />
          <h1>{t('Authors')}</h1>
          {isLoading ? (
            <Spin />
          ) : (
            <div className={cn(s.list, !isLoading && s.loaded)}>
              {authors.map((author, key) => (
                <Author
                  {...author}
                  match={match}
                  key={key}
                  scrollPosition={scrollPosition}
                />
              ))}
            </div>
          )}
          <Pagination {...meta} page={page} match={match} />
        </Container>
      </Container>
    </div>
  );
};

export default withTranslation()(Authors);

import { Author } from './author';
import { Authors } from './authors';
import { Contacts } from './contacts';
import { CreateArticle } from './create-article';
import { Home } from './home';
import { Issue } from './issue';
import { Issues } from './issues';
import { AllIssues } from './all-issues';
import { Book } from './book';
import { Books } from './books';
import { NotFound } from './not-found';
import { Periodicals } from './periodicals';

const routes = [
  {
    path: '/authors/:id',
    component: Author,
  },
  {
    path: '/authors',
    component: Authors,
  },
  {
    path: '/contacts',
    component: Contacts,
  },
  {
    path: '/create-article',
    component: CreateArticle,
  },
  {
    path: '/',
    component: Home,
  },
  {
    path: '/periodicals/:id/:issueId',
    component: Issue,
  },
  {
    path: '/all-issues',
    component: AllIssues,
  },
  {
    path: '/books',
    component: Books,
  },
  {
    path: '/books/:id',
    component: Book,
  },
  {
    path: '/periodicals/:id',
    component: Issues,
  },
  {
    path: '/periodicals',
    component: Periodicals,
  },
  {
    path: '/*',
    component: NotFound,
    exact: false,
  },
];
export { NotFound } from './not-found';
export default routes;

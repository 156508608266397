import React, { useEffect } from 'react';
import { Helmet } from 'react-helmet';
import { withTranslation } from 'react-i18next';
import cn from 'classnames';
import { useDataApi, Book, Author as AuthorC } from 'components';
import { Container, Breadcrumbs, Spin, MainTitle } from 'ui-kit';
import { LazyLoadImage } from 'react-lazy-load-image-component';
import s from './author.module.scss';

const Author = ({ t, i18n, className, match, scrollPosition }) => {
  const lang = i18n.language;
  const { id } = match.params;
  const urlBooksOfAuthor = `/books?limit=5&offset=0&f.authors=${id}&versions=true`;
  const urlAuthors = `/authors?limit=5&offset=0`;
  const urlAuthor = `/authors/${id}`;
  const [
    { data: dataBooks, isLoading: isLoadingBooks },
    getBooks,
  ] = useDataApi.useDataApi(urlBooksOfAuthor, { books: {} });
  useEffect(() => {
    getBooks(urlBooksOfAuthor);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [urlBooksOfAuthor]);
  const [
    { data: dataAuthors, isLoading: isLoadingAuthors },
    getAuthors,
  ] = useDataApi.useDataApi(urlAuthors, { authors: {} });
  useEffect(() => {
    getAuthors(urlAuthors);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [urlAuthors]);
  const [{ data: author }, getAuthor] = useDataApi.useDataApi(urlAuthor, {
    author: {},
  });
  useEffect(() => {
    getAuthor(urlAuthor);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [urlAuthor]);
  const { books } = dataBooks;
  const { authors } = dataAuthors;
  const { photo, type, description } = author;
  const firstName = author.firstName?.[lang];
  const lastName = author.lastName?.[lang];
  return (
    <div className={cn(className, s.author)}>
      <Helmet
        title="Author Page"
        meta={[
          { name: 'description', content: 'Description for Author page.' },
        ]}
      />
      <Container>
        <Container full={false}>
          <Breadcrumbs
            paths={[
              {
                title: 'authors',
                path: '/authors',
              },
              {
                title: `${firstName} ${lastName}`,
                path: `/${firstName}.${lastName}`,
              },
            ]}
          />
          <div className={s.author}>
            <LazyLoadImage
              className={s.photo}
              wrapperClassName={s.qwq}
              scrollPosition={scrollPosition}
              placeholderSrc="/images/default-author.png"
              src={photo || '/images/default-author.png'}
              alt=""
            />
            <div>
              <h2>
                {firstName} {lastName}
              </h2>
              <div className={s.type}>{type}</div>
              <p>{description}</p>
            </div>
          </div>
          {isLoadingBooks ? (
            <Spin />
          ) : (
            !!books.length && (
              <>
                <MainTitle texts={['Книги', 'Автора']} className={s.title} />
                <div className={s.list}>
                  {books.map((book, key) => (
                    <Book
                      {...book}
                      scrollPosition={scrollPosition}
                      match={match}
                      key={key}
                    />
                  ))}
                </div>
              </>
            )
          )}
          <MainTitle texts={['Другие', 'Авторы']} className={s.title} />
          {isLoadingAuthors ? (
            <Spin />
          ) : (
            <div className={s.list}>
              {authors.map((author, key) => (
                <AuthorC
                  {...author}
                  scrollPosition={scrollPosition}
                  match={match}
                  key={key}
                />
              ))}
            </div>
          )}
        </Container>
      </Container>
    </div>
  );
};

export default withTranslation()(Author);

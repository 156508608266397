const uk = {
  lifesource: 'Джерело життя',
  'christian-publishing': 'християнське видавництво',
  Home: 'Головна',
  Books: 'Книги',
  'new books': 'нові книги',
  'Home page': 'Головна сторінка',
  books: 'книги',
  periodicals: 'періодика',
  Periodicals: 'Періодика',
  contacts: 'контакти',
  authors: 'автори',
  author: 'автор',
  Authors: 'Автори',
  Contacts: 'Контакти',
  magazine: 'журнал',
  newspaper: 'газета',
  freq: 'періодичність',
  copies: 'тираж',
  monthly: 'щомісячний',
  Monthly: 'щомісячний',
  quarterly: 'щоквартальний',
  Quarterly: 'щоквартальний',
  bimonthly: '1 раз на 2 місяці',
  Bimonthly: '1 раз на 2 місяці',
  semiannually: '1 раз на півроку',
  Semiannually: '1 раз на півроку',
  annually: 'щорічний',
  Annually: 'щорічний',
  'on bracket': 'скоба',
  soft: 'мягкий',
  hard: 'твердий',
  search: 'Знайти',
  'search results': 'результати пошуку',
  'no matches for': 'немає збігів для',
  paper: 'паперова',
  'all-issues': 'Усі випуски',
  categories: 'категорії',
  'recommended price': 'рекомендована ціна',
  uah: 'грн',
  'cover type': 'палітурка',
  format: 'формат',
  mm: 'мм',
  'where to buy': 'де купити',
  evg: 'євангеліст',
  writer: 'письменник',
  'read all': 'Читати всю книгу',
  'read fragment': 'Читати фрагмент',
  'read issue': 'Читати випуск',
  'download book for free': 'завантажити книгу безкоштовно',
  'home.about1':
    'Видавництво «Джерело життя» засноване 1994 року і є одним із перших в Україні видавництв, що випускають християнську літературу. Сьогодні це широкий асортимент видань українською та російською мовами для всіх вікових груп: дітей, підлітків, молоді, дорослих.',
  'home.about2':
    'Наша мета – через друковане слово поширювати духовні цінності, принципи здорового способу життя та сімейного добробуту, дарувати надію, надихати, вчити, спонукати до добра та пізнання істини, адже тільки істина може зробити людину по-справжньому вільною та щасливою.',
  'home.accordion.title1': 'Наші принципи',
  'home.accordion.text1.1':
    'Ми сповідуємо Триєдиного Бога як Творця і Викупителя людства, дотримуємося принципів Десятислівного Закону, включно з четвертою заповіддю щодо суботи, та очікуємо близького Другого повернення Христа на Землю.',
  'home.accordion.text1.2':
    'Ми сповідуємо чистоту сімейних стосунків, які виключають інтимні взаємини до шлюбу та поза ним і за Божим задумом передбачають духовне, емоційне та фізичне єднання чоловіка і жінки в атмосфері взаєморозуміння, поваги та християнської любові.',
  'home.accordion.text1.3':
    'Ми сповідуємо здоровий спосіб життя, заснований на восьми принципах здоров’я: збалансоване харчування (вживання чистої їжі, згідно з Левит 11), достатня фізична активність, повноцінний відпочинок, використання води та сонячного світла, правильне дихання, відмова від шкідливих звичок та цілковита довіра Богові.',
  'home.accordion.text1.4':
    'Ми сповідуємо моральні цінності, які ґрунтуються на біблійних принципах та істинах, відкритих у Дусі пророцтва. Це гармонійні стосунки з Творцем та людьми, чесність в усіх сферах життя, саморозвиток та постійне духовне зростання.',
  'home.accordion.title2': 'Тематика книг',
  'home.accordion.text2.1':
    'Ми видаємо книги українських та закордонних авторів, які вірять у Бога і в силу друкованого слова. Це науковці, публіцисти, християнські письменники.',
  'home.accordion.text2.2':
    'Усі видання можна поділити на такі тематичні групи:',
  'home.accordion.text2.3':
    'книги про духовні та моральні цінності: як знайти сенс буття, як пізнати характер Бога, як удосконалити себе, як успішно долати життєві випробування, як дбати про свій духовний розвиток;',
  'home.accordion.text2.4':
    'книги про здоровий спосіб життя: лікування та профілактика поширених захворювань, природні засоби лікування, вегетаріанське харчування, методи подолання стресу та здобуття душевної рівноваги, секрети довголіття;',
  'home.accordion.text2.5':
    'книги сімейної тематики: як створити щасливу християнську сім’ю, як розв’язувати конфлікти, як упоратися з гнівом, як подолати труднощі в шлюбі, як зробити іншого щасливим;',
  'home.accordion.text2.6':
    'книги з виховання дітей: як виховати слухняних дітей, які секрети щасливого батьківства, як пережити підліткові проблеми, як прищепити дитині інтерес до духовних істин, у чому суть християнських принципів виховання;',
  'home.accordion.text2.7':
    'дитяча література: розмальовки, християнські оповідання для наймолодших, англійські абетки, пригодницькі історії, розвивальні книги;',
  'home.accordion.text2.8':
    'книги для молоді: як знайти надійних друзів, як будувати стосунки, як обрати супутника життя, які секрети досягнення успіху, як позитивно впливати на людей;',
  'home.accordion.text2.9':
    'художня література: життєві історії християн, автобіографії цікавих людей, шлях до успіху через випробування.',
  'home.accordion.text2.10':
    'Це книги зі змістом, які варто читати кожному. Вони здатні збагатити духовно, підбадьорити фізично, наситити інтелектуально.',
  'home.accordion.title3': 'Періодика',
  'home.accordion.text3.1':
    'Ми щомісяця пропонуємо своїм читачам нову порцію актуальної й цікавої інформації для поглиблення духовного світогляду. Наші періодичні видання проходять фахову експертизу науковців і містять перевірену інформацію з надійних джерел. Серед наших дописувачів – доктори та кандидати наук, публіцисти, поети та пересічні читачі.',
  'home.accordion.text3.2': 'Наші газети:',
  'home.accordion.text3.3':
    'газета про духовні цінності «Вічний Скарб» / «Вечное Сокровище»',
  'home.accordion.text3.4': 'газета про здоров’я «Моя здорова сім’я»',
  'home.accordion.text3.5': 'дитяча християнська газета «Скарбничка»',
  'home.accordion.text3.6': 'молодіжна християнська газета «Флешка»',
  'home.accordion.text3.7': 'внутріцерковна газета «Адвентистський вісник»',
  'home.accordion.text3.8': 'Наші журнали:',
  'home.accordion.text3.9': 'жіночий журнал «Маргарита»',
  'home.accordion.text3.10': 'суспільно-науковий журнал «Ознаки часу»',
  'home.accordion.text3.11': 'дитячий журнал про подорожі «Дружболандія»',
  'home.accordion.text3.12': 'підлітковий кишеньковий журнал «Скайфон»',
  'home.accordion.title4': 'Наші бестселери',
  'home.accordion.text4.1':
    'Серія «Конфлікт віків»: «Патріархи і пророки», «Пророки і царі», «Христос – надія світу» («Бажання віків»), «Дії апостолів», «Велика боротьба».',
  'home.accordion.text4.2':
    'Автор цієї серії книг – відома християнська письменниця Еллен Уайт (1827-1915). У п’яти томах викладений весь хід історії людства від початку й до близького завершення, описана велика боротьба між добром і злом, План спасіння, походження, розвиток і знищення зла.',
  'home.accordion.text4.3': '«Золотые руки» Бен Карсон.',
  'home.accordion.text4.4':
    'Шлях успіху від безнадійного двієчника в школі до всесвітньо відомого лікаря, на рахунку якого десятки унікальних операцій. Від невдахи до людини, яка здобула світове визнання. Як це можливо? Автор книги не робить із цього секрету, а охоче ділиться своїм рецептом успіху з іншими.',
  'home.accordion.text4.5': '«Депрессия: как ее победить?» Нил Недли. ',
  'home.accordion.text4.6':
    'Ця книга корисна не тільки для тих, хто страждає від депресії, а й для всіх, чиє життя пов’язане з частими стресами, хто час від часу відчуває напади тривожності, поганого настрою та втоми. Її автор на підставі сучасних досліджень вказує шлях запобігання та подолання цих психічних розладів: це і фізичні вправи, і робота над мисленням, і зміна раціону харчування.',
  'home.accordion.text4.7':
    '«Секреты здоровья и долголетия» Алексей Хацинский.',
  'home.accordion.text4.8':
    'У книзі містяться 365 здорових порад на щодень. Якщо ви бажаєте зберегти чи відновити своє здоров’я, тоді вам потрібно щодня робити один крок йому назустріч. Зміни на краще не змусять себе довго чекати, і ви зберете рясний урожай здоров’я.',
  'home.accordion.text4.9': '«Отважьтесь просить о большем» Мелоді Мейсон.',
  'home.accordion.text4.10':
    'Ця книга ожвавить і впорядкує ваше молитовне життя, а також стане ефективним практичним посібником для молитовних служінь. Автор закликає нас просити про більше, адже «молитва – це ключ у руках віри, що відкриває небесну скарбницю». Ми частіше схилятимемося на коліна, якщо усвідомимо істинну силу молитви. А чим для вас є молитва – кермом чи аварійним гальмом?',
};

export default uk;

import React, { useEffect, useState, useReducer } from 'react';
import cn from 'classnames';
import { Helmet } from 'react-helmet';
import { Collapse } from 'react-collapse';
import { withTranslation } from 'react-i18next';

import { Issue, useDataApi } from 'components';
import { getSearchParams } from 'utils';
import { Breadcrumbs, Container, Spin, Pagination } from 'ui-kit';
import s from './issues.module.scss';

const Issues = ({
  t,
  className,
  match,
  location,
  history,
  i18n,
  scrollPosition,
}) => {
  const { id } = match.params;
  const params = getSearchParams(location.search);
  const { language: lang } = i18n;

  const initilaFilters = {
    // periodicalId: '',
    publishyear: '',
  };

  const reducer = (state, newState) => ({ ...state, ...newState });

  const [state, setState] = useReducer(reducer, initilaFilters);
  const [page, setPage] = useState(params.page || 1);
  const onPage = 12;
  const currentYear = new Date().getFullYear();

  const [{ data, isLoading }, setUrl] = useDataApi.getPeriodicalIssues({
    id,
    limit: onPage,
    offset: onPage * (page - 1),
    // public: true,
    versions: true,
  });

  const { publishyear } = state;

  useEffect(() => {
    if (params.page) setPage(params.page);
  }, [params.page]);

  useEffect(() => {
    const filters = {};

    for (const [key, value] of Object.entries(state)) {
      if (value) filters[`f.${key}`] = state[key];
    }

    setUrl({
      limit: onPage,
      offset: onPage * (page - 1),
      // public: true,
      versions: true,
      ...filters,
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [page, publishyear]);

  const { issues, meta } = data;

  // const [dataPeriodicals] = useDataApi.getPeriodicals({
  //   public: true,
  //   versions: true,
  // })

  const updateFilter = (newFilter) => {
    history.push(`/${lang}/periodicals/${id}?page=1`);
    setState({
      ...state,
      ...newFilter,
    });
  };

  const [isOpenFilters, setIsOpenFilters] = useState('');
  const toggleFilters = () => {
    setIsOpenFilters(!isOpenFilters);
  };

  let periodicalTitle = issues?.[0]?.periodical?.title;

  if (isClient && periodicalTitle) {
    localStorage.setItem('periodicalTitle', periodicalTitle);
  }

  periodicalTitle = isClient && localStorage.getItem('periodicalTitle');

  return (
    <div className={cn(className, s.issues)}>
      <Helmet
        title="Issues"
        meta={[
          { name: 'description', content: 'Description for Issues page.' },
        ]}
      />
      <Container>
        <Container full={false}>
          <Breadcrumbs
            paths={[
              {
                title: 'periodicals',
                path: '/periodicals',
              },
              {
                title: periodicalTitle,
                path: `/periodicals/${id}`,
              },
            ]}
          />
          <h1>
            {issues?.[0]?.periodical?.title}
            <div className={s.filterTitle} onClick={toggleFilters}>
              <div className={s.text}>Фильтры</div>
              <div className={cn(s.filtersIcon, isOpenFilters && s.open)}>
                <div />
                <div />
                <div />
              </div>
            </div>
          </h1>
          <Collapse isOpened={isOpenFilters}>
            <div className={s.filters}>
              {/* <div className={s.filter}>
                <div className={s.title}>
                  Категории:
                </div>
                <div className={s.options}>
                  {dataPeriodicals.data.periodicals?.map(({ id, title }) => {
                const isActive = id == periodicalId
                return (
                <div
                key={id}
                className={cn(s.option, isActive && s.active)}
                onClick={isActive ? () => updateFilter({ periodicalId: '' }) : () => updateFilter({ periodicalId: id })}
                >
                {title}
                <img
                src="/images/svg/cross@1x.svg"
                srcSet="/images/svg/cross@1x.svg 1x, /images/svg/cross@2x.svg 2x"
                className={s.close}
                />
                </div>
                )
                  })}
                </div>
              </div> */}
              <div className={s.filter}>
                <div className={s.title}>Год:</div>
                <div className={s.options}>
                  {Array(currentYear - 2012)
                    .fill()
                    .map((item, i) => {
                      const year = currentYear - i;
                      const isActive = year === state.publishyear;
                      return (
                        <div
                          key={year}
                          className={cn(s.option, isActive && s.active)}
                          onClick={
                            isActive
                              ? () => updateFilter({ publishyear: '' })
                              : () => updateFilter({ publishyear: year })
                          }
                        >
                          {year}
                          <img
                            alt="cross"
                            src="/images/svg/cross@1x.svg"
                            srcSet="/images/svg/cross@1x.svg 1x, /images/svg/cross@2x.svg 2x"
                            className={s.close}
                          />
                        </div>
                      );
                    })}
                </div>
              </div>
            </div>
          </Collapse>
          {isLoading ? (
            <Spin />
          ) : (
            <div className={cn(s.list, !isLoading && s.loaded)}>
              {issues?.map((issue, key) => (
                <Issue
                  {...issue}
                  match={match}
                  scrollPosition={scrollPosition}
                  key={key}
                  isAnimate
                />
              ))}
            </div>
          )}
          <Pagination {...meta} page={page} match={match} />
        </Container>
      </Container>
    </div>
  );
};

export default withTranslation()(Issues);

import React from 'react';
import { Helmet } from 'react-helmet';
import { withTranslation } from 'react-i18next';

import cn from 'classnames';
import { Container, Breadcrumbs } from 'ui-kit';
import s from './contacts.module.scss';

const Contacts = ({ t, className, match }) => (
  <div className={cn(className, s.contacts)}>
    <Helmet
      title="Contacts Page"
      meta={[
        { name: 'description', content: 'Description for Contacts page.' },
      ]}
    />
    <Container>
      <Container full={false}>
        <Breadcrumbs
          paths={[
            {
              title: 'contacts',
              path: '/contacts',
            },
          ]}
        />
        <h1>{t('Contacts')}</h1>
        <div className={s.numbers}>
          <div className={s.number}>
            <img src="/images/hotline.png" alt="" />
            <div className={s.title}>Гарячая линия</div>
            <div className={s.phone}>0 ( 800 ) 30 20 20</div>
            <p>
              Бесплатно с мобильных телефонов.
              <br />
              Каждый день: 07:00 - 22:00
            </p>
          </div>
          <div className={s.number}>
            <img src="/images/office.png" alt="" />
            <div className={s.title}>Офис издательства</div>
            <div className={s.phone}>( 044 ) 425 69 06</div>
            <p>
              Звонки, согласно тарифам оператора.
              <br />
              Будние дни: 10:00 - 17:00
            </p>
          </div>
        </div>
        <address>
          <div className={s.address}>
            <img src="/images/office.png" alt="" />
            <div className={s.title}>Адрес</div>
            <p>
              ул. Лукьяновская, 9/10А
              <br />
              г.Киев, Украина
            </p>
          </div>
          <div className={s.schedule}>
            <img src="/images/office.png" alt="" />
            <div className={s.title}>График работы</div>
            <div className={s.list}>
              <p className={s.item}>
                пн-чт:
                <br />
                09:00 - 17:00
              </p>
              <p className={s.item}>
                пятница:
                <br />
                09:00 - 13:00
              </p>
            </div>
          </div>
        </address>
      </Container>
    </Container>
  </div>
);

export default withTranslation()(Contacts);

import React, { useContext } from 'react';
import cn from 'classnames';
import { WidthContext } from 'contexts/width-context';
import { Link } from 'ui-kit';
import s from './pagination.module.scss';

const Pagination = ({ match, className, total, limit, page }) => {
  const { isMob } = useContext(WidthContext);

  const pages = Math.ceil(total / limit) || 1;

  // const VISIBLE_PAGES = isMob ? 1 : 2
  return (
    <div className={cn(s.pagination, className)}>
      <Link
        to={`${match.url}?page=${page - 1}`}
        disabled={page === 1}
        className={cn(s.button, s.prev)}
      >
        <img src="/images/svg/arrow-left-v2.svg" alt="" />
      </Link>

      {Array(pages)
        .fill()
        .map((num, key) => {
          const isFirst = key + 1 === 1;
          const isLast = key + 1 === pages;
          const isCurrent = page === key + 1;

          const before =
            page > (isMob ? 1 : 2) &&
            [...(isMob ? [page - 1] : [page - 1, page - 2, page - 3])].includes(
              key + 1
            );
          const after =
            page < pages - (isMob ? 1 : 2) &&
            [...(isMob ? [page + 1] : [page + 1, page + 2, page + 3])].includes(
              key + 1
            );

          return (
            <React.Fragment key={key}>
              {(isFirst || isLast || isCurrent || before || after) && (
                <>
                  {before &&
                    key === page - (isMob ? 2 : 4) &&
                    key !== 0 &&
                    key !== 1 &&
                    '...'}

                  <Link
                    key={key}
                    children={key + 1}
                    to={`${match.url}?page=${key + 1}`}
                    className={cn(
                      s.button,
                      s.number,
                      key + 1 === page && s.active
                    )}
                  />

                  {after &&
                    key === page + (isMob ? 0 : 2) &&
                    key + 1 !== pages &&
                    key !== pages &&
                    '...'}
                </>
              )}
            </React.Fragment>
          );
        })}

      <Link
        to={`${match.url}?page=${page + 1}`}
        disabled={page === pages}
        className={cn(s.button, s.next)}
      >
        <img src="/images/svg/arrow-left-v2.svg" alt="" />
      </Link>
    </div>
  );
};

export default Pagination;

import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';
import { en, uk, ru } from 'translates';

const resources = {
  ru: {
    translations: ru,
  },
  en: {
    translations: en,
  },
  uk: {
    translations: uk,
  },
};

i18n
  // .use(LanguageDetector)
  .use(initReactI18next)
  .init({
    // we init with resources
    resources,

    fallbackLng: 'ru',
    // debug: true,

    // have a common namespace used around the full app
    ns: ['translations'],
    defaultNS: 'translations',

    keySeparator: false, // we use content as keys

    interpolation: {
      escapeValue: false, // not needed for react!!
      formatSeparator: ',',
    },

    react: {
      wait: true,
    },

    // htmlTag: document.documentElement
  });

export const langs = Object.keys(resources);

export default i18n;

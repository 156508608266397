import React from 'react';
import cn from 'classnames';
import { withTranslation } from 'react-i18next';

import { NavLink } from 'ui-kit';
import s from './header.module.scss';

const Nav = ({ t, hide }) => (
  <nav className={cn(s.nav, hide && s.hide)}>
    <NavLink exact to="/" className={s.link} children={t('Home')} />
    <NavLink exact to="/books" className={s.link} children={t('Books')} />
    <NavLink
      exact
      to="/periodicals"
      className={s.link}
      children={t('periodicals')}
    />
    <NavLink exact to="/authors" className={s.link} children={t('authors')} />
    {/* <NavLink
      exact
      to='/articles'
      className={s.link}
      children={t('articles')}
    /> */}
    <NavLink exact to="/contacts" className={s.link} children={t('contacts')} />
  </nav>
);

export default withTranslation()(Nav);

import React, { useState, useEffect } from 'react';
import { withRouter } from 'react-router';
import { Switch, Route, Redirect } from 'react-router-dom';
import { withTranslation } from 'react-i18next';
import { toast } from 'react-toastify';
import { trackWindowScroll } from 'react-lazy-load-image-component';
import { Header, Footer } from 'components';
import routes, { NotFound } from 'routes';

import s from './app.module.scss';

toast.configure();

const App = ({ i18n, history, location, scrollPosition }) => {
  const DEFAULT_LANG = 'ru';
  const { language } = i18n;
  const { hash, pathname, search } = location;
  const fullPath = pathname + search + hash;
  const availableLangs = ['en', 'uk'];
  let langFromUrl = pathname.match(/\/((\w{2})\/?$|(\w{2})\/)/);
  langFromUrl = langFromUrl ? langFromUrl[0].split('/').join('') : '';
  const isInitialLang = availableLangs.includes(langFromUrl);

  const [lang, setLang] = useState((isInitialLang && langFromUrl) || language);

  const isDefaultLang = lang === DEFAULT_LANG;

  useEffect(() => {
    window.scrollTo({ top: 0 });
  }, [fullPath]);

  useEffect(() => {
    document.documentElement.lang = lang;
    i18n.changeLanguage(lang);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [lang]);

  const onChangeLang = (e) => {
    const lang = e.target.value;
    setLang(lang);

    const fullPathString = pathname + search + hash;

    const fullPath = fullPathString.match(/\/((\w{2})\/?$|(\w{2})\/)/);

    const fullPathWihtoutLang = fullPath
      ? fullPathString.replace(fullPath[0], '/')
      : fullPathString;

    const redirectUrl =
      lang === DEFAULT_LANG
        ? fullPathWihtoutLang
        : `/${lang}${fullPathWihtoutLang}`;

    history.push(redirectUrl);
  };

  return (
    <div className={s.wrapper}>
      <Header {...{ onChangeLang, history }} />
      <main>
        <Switch>
          <Route
            path="/:lang(ru*)"
            render={({ location }) => (
              <Redirect to={location.pathname.slice(3)} />
            )}
          />
          <Switch>
            {routes.map(({ component: Component, path, exact = true }, key) => (
              <Route
                {...{ key, exact }}
                path={isDefaultLang ? `${path}` : `/${lang}${path}`}
                render={(props) => (
                  <Component
                    {...props}
                    isDefaultLang={isDefaultLang}
                    scrollPosition={scrollPosition}
                    className={s.route}
                  />
                )}
              />
            ))}
          </Switch>
          <Route
            path="/*"
            render={(props) => <NotFound {...props} className={s.route} />}
          />
        </Switch>
      </main>
      <Footer {...{ onChangeLang }} />
    </div>
  );
};

export default withRouter(trackWindowScroll(withTranslation()(App)));

import React, { Fragment } from 'react';
import cn from 'classnames';
import { withTranslation } from 'react-i18next';

import { Link } from 'ui-kit';
import s from './breadcrumbs.module.scss';

const Breadcrumbs = ({ t, paths }) => (
  <div className={s.breadcrumbs}>
    <Link className={s.item} to="/">
      {t('Home')}
    </Link>
    {paths.map(({ title, path }, key) => {
      const fullPath = paths
        .slice(0, key + 1)
        .map(({ path }) => path)
        .join('');
      const isLast = paths.length === key + 1;
      return (
        <Fragment key={key}>
          <img src="/images/svg/arrow-left-v2.svg" alt="" />
          {isLast ? (
            <span className={cn(s.item, s.last)}>{t(title)}</span>
          ) : (
            <Link to={fullPath} className={s.item} children={t(title)} />
          )}
        </Fragment>
      );
    })}
  </div>
);

export default withTranslation()(Breadcrumbs);

import React, { useContext } from 'react';
import cn from 'classnames';
import { withTranslation } from 'react-i18next';
import { WidthContext } from 'contexts/width-context';
import { Periodical } from 'components';
import { Link, MainTitle } from 'ui-kit';
import s from '../issue.module.scss';

const OtherPeriodicals = ({
  t,
  className,
  periodicals,
  match,
  scrollPosition,
}) => {
  const { isTabDown } = useContext(WidthContext);
  return (
    <div className={cn(s.block, s.periodicals)}>
      <div className={s.head}>
        <MainTitle className={s.mainTitle} texts={['Похожие', 'издания']} />
        <Link data-v2 to="/periodicals">
          {!isTabDown && 'показать все'}
          <img src="/images/svg/arrow-left-v2.svg" alt="" />
        </Link>
      </div>
      <div className={s.list}>
        {periodicals?.map((periodical, key) => (
          <Periodical
            key={key}
            match={match}
            {...periodical}
            scrollPosition={scrollPosition}
          />
        ))}
      </div>
    </div>
  );
};

export default withTranslation()(OtherPeriodicals);

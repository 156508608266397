import React from 'react';
import cn from 'classnames';
import { NavLink as RouterNavLink } from 'react-router-dom';
import { withTranslation } from 'react-i18next';
import s from './nav-link.module.scss';

const NavLink = ({ tReady, t, className, i18n, to, ...props }) => {
  const { language } = i18n;
  const DEFAULT_LANG = 'ru';
  return (
    <RouterNavLink
      {...props}
      to={language === DEFAULT_LANG ? to : `/${language}${to}`}
      className={cn(s.navLink, className)}
      activeClassName={s.active}
    />
  );
};

export default withTranslation()(NavLink);

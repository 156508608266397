import React from 'react';
import cn from 'classnames';
import { withTranslation } from 'react-i18next';

import { MainTitle } from 'ui-kit';
import s from '../issue.module.scss';

function IssueInformation({ t, issue: { description, periodical, info } }) {
  const props = {
    ...periodical?.info,
    ...info,
  };

  delete props.width;
  delete props.height;

  let format = '';

  if (info?.width && info?.height) {
    format = `${info.width}x${info.height}`;
  } else if (periodical?.info?.width && periodical?.info?.height) {
    format = `${periodical.info.width}x${periodical.info.height}`;
  }

  return (
    <div className={cn(s.block, s.information)}>
      <div className={s.head}>
        <MainTitle
          className={s.mainTitle}
          texts={['Информация', 'о журнале']}
        />
      </div>
      <div className={s.description}>{periodical?.description}</div>
      <ul className={s.list}>
        {periodical?.language && (
          <li className={s.item}>
            <span className={s.label}>Язык:</span>
            <span className={s.value}>
              {periodical?.language === 'ru' ? ' Русский' : ' Украинский'}
            </span>
          </li>
        )}
        {Object.entries(props).map(
          (prop, key) =>
            prop[1] && (
              <li className={s.item} key={key}>
                <span className={s.label}>{t(prop[0])}: </span>
                <span className={s.value}>{t(prop[1])}</span>
              </li>
            )
        )}
        {/* {!!pages && (
          <li className={s.item}>
            <span className={s.label}>Страниц: </span>
            <span className={s.value}>{pages}</span>
          </li>
          )}
          {!!copies && (
          <li className={s.item}>
            <span className={s.label}>Тираж: </span>
            <span className={s.value}>{copies}</span>
          </li>
        )} */}
        {format && (
          <li className={s.item}>
            <span className={s.label}>Формат: </span>
            <span className={s.value}>{format}</span>
          </li>
        )}
      </ul>
    </div>
  );
}

export default withTranslation()(IssueInformation);

import React from 'react';
import cn from 'classnames';

import s from './spin.module.scss';

const Spin = ({ className }) => (
  <div className={cn(s.spinWrap, className)}>
    <div className={s.spin}>
      <div />
      <div />
      <div />
      <div />
    </div>
  </div>
);
export default Spin;

import React, { useEffect, useState, useReducer } from 'react';
import cn from 'classnames';
import { Helmet } from 'react-helmet';
import { Collapse } from 'react-collapse';
import { withTranslation } from 'react-i18next';
import { Book, useDataApi } from 'components';
import { getSearchParams } from 'utils';
import { Breadcrumbs, Container, Spin, Pagination, Switch } from 'ui-kit';
import s from './books.module.scss';

const Books = ({ t, className, match, location, scrollPosition }) => {
  const initilaState = {
    isOpenFilters: false,
    filters: {
      language: '',
      eBookPrice: '',
      categories: '',
      year: '',
    },
  };

  const params = getSearchParams(location.search);

  const reducer = (state, newState) => ({ ...state, ...newState });
  const [state, setState] = useReducer(reducer, initilaState);

  const [page, setPage] = useState(params.page || 1);

  const onPage = 12;

  const [{ data, isLoading }, setUrl] = useDataApi.getBooks({
    limit: onPage,
    offset: onPage * (page - 1),
  });

  const { filters, isOpenFilters } = state;
  const { categories, year, language, eBookPrice } = filters;

  useEffect(() => {
    if (params.page) setPage(params.page);
  }, [params.page]);

  useEffect(() => {
    const filters = {};

    for (const [key, value] of Object.entries(state.filters)) {
      if (value !== '') {
        if (['year', 'language'].includes(key)) {
          filters[`f.versions.${key}`] = state.filters[key];
        } else {
          filters[`f.${key}`] = state.filters[key];
        }
      }
    }

    setUrl({
      limit: onPage,
      offset: onPage * (page - 1),
      ...filters,
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [page, categories, year, language, eBookPrice]);

  const { books, meta } = data;

  const [dataCategories] = useDataApi.getCategories({});

  const updateFilter = (newFilter) => {
    setState({
      filters: {
        ...state.filters,
        ...newFilter,
      },
    });
  };

  const toggleFilters = () => {
    setState({
      isOpenFilters: !isOpenFilters,
    });
  };

  const languages = [
    {
      id: 'uk',
      title: 'Укр',
    },
    {
      id: 'ru',
      title: 'Рус',
    },
  ];

  return (
    <div className={cn(className, s.books)}>
      <Helmet
        title="Books"
        meta={[{ name: 'description', content: 'Description for Books page.' }]}
      />
      <Container>
        <Container full={false}>
          <Breadcrumbs
            paths={[
              {
                title: 'books',
                path: '/books',
              },
            ]}
          />
          <h1>
            {t('Books')}
            <div className={s.filterTitle} onClick={toggleFilters}>
              <div className={s.text}>Фильтры</div>
              <div className={cn(s.filtersIcon, isOpenFilters && s.open)}>
                <div />
                <div />
                <div />
              </div>
            </div>
          </h1>
          <Collapse isOpened={isOpenFilters}>
            {/* <div className={s.filters}>
              {meta?.filters.map(filter => {
                console.log('filter', filter)

                return (
              <div className={s.filter}>

              </div>
                )
              })}
            </div> */}
            <div className={s.filters}>
              <div className={s.filter}>
                <div className={s.title}>Только с EBOOK:</div>
                <Switch
                  onClick={({ target }) =>
                    updateFilter({ eBookPrice: target.checked ? 0 : '' })
                  }
                />
              </div>
            </div>
            {/* <div className={s.filters}>
              <div className={s.filter}>
                <div className={s.title}>
              Года:
                </div>
                <Range
              label="Label"
              options={[2010, 2011, 2012, 2013, 2014, 2015]}
              onChange={years => console.log('years', years)}
              className={s.range}
                />
              </div>
              <div className={s.filter}>
                <div className={s.title}>
              Диапазаон цен:
                </div>
                <Range
              label="Label"
              fromTo={[135, 4000]}
              onChange={priceRange => console.log('priceRange', priceRange)}
              className={s.range}
                />
              </div>
            </div> */}
            <div className={s.filters}>
              <div className={s.filter}>
                <div className={s.title}>Категории:</div>
                <div className={s.options}>
                  {dataCategories.data.categories?.map(({ id, title }) => {
                    const isActive = id === categories;
                    return (
                      <div
                        key={id}
                        className={cn(s.option, isActive && s.active)}
                        onClick={
                          isActive
                            ? () => updateFilter({ categories: '' })
                            : () => updateFilter({ categories: id })
                        }
                      >
                        {title}
                        <img
                          src="/images/svg/cross@1x.svg"
                          srcSet="/images/svg/cross@1x.svg 1x, /images/svg/cross@2x.svg 2x"
                          className={s.close}
                          alt="toggle category filter" // TODO: translate
                        />
                      </div>
                    );
                  })}
                </div>
              </div>
              <div className={s.filter}>
                <div className={s.title}>Язык:</div>
                <div className={s.options}>
                  {languages.map(({ id, title }) => {
                    const isActive = id === language;
                    return (
                      <div
                        key={id}
                        className={cn(s.option, isActive && s.active)}
                        onClick={
                          isActive
                            ? () => updateFilter({ language: '' })
                            : () => updateFilter({ language: id })
                        }
                      >
                        {title}
                        <img
                          src="/images/svg/cross@1x.svg"
                          srcSet="/images/svg/cross@1x.svg 1x, /images/svg/cross@2x.svg 2x"
                          className={s.close}
                          alt="toggle language filter" // TODO: translate
                        />
                      </div>
                    );
                  })}
                </div>
              </div>
              <div className={s.filter}>
                <div className={s.title}>Год:</div>
                <div className={s.options}>
                  {[
                    '2006',
                    '2007',
                    '2008',
                    '2009',
                    '2010',
                    '2011',
                    '2012',
                    '2013',
                    '2014',
                    '2015',
                    '2016',
                    '2017',
                    '2019',
                  ]
                    .reverse()
                    .map((year) => {
                      const isActive = year === state.filters.year;
                      return (
                        <div
                          key={year}
                          className={cn(s.option, isActive && s.active)}
                          onClick={
                            isActive
                              ? () => updateFilter({ year: '' })
                              : () => updateFilter({ year })
                          }
                        >
                          {year}
                          <img
                            src="/images/svg/cross@1x.svg"
                            srcSet="/images/svg/cross@1x.svg 1x, /images/svg/cross@2x.svg 2x"
                            className={s.close}
                            alt="toggle year filter" // TODO: translate
                          />
                        </div>
                      );
                    })}
                </div>
              </div>
            </div>
          </Collapse>
          {isLoading ? (
            <Spin />
          ) : (
            <div
              className={cn(
                s.list,
                !isLoading && s.loaded,
                !books?.length && s.nothing
              )}
            >
              {books?.length
                ? books?.map((book, key) => (
                    <Book
                      {...book}
                      match={match}
                      key={key}
                      isAnimate
                      scrollPosition={scrollPosition}
                    />
                  ))
                : 'Ничего не найдено'}
            </div>
          )}
          <Pagination {...meta} page={page} match={match} />
        </Container>
      </Container>
    </div>
  );
};

export default withTranslation()(Books);

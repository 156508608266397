import React from 'react';
import cn from 'classnames';
import { withTranslation } from 'react-i18next';

import { NavLink, Select, SelectOption } from 'ui-kit';
import s from './header.module.scss';

const NavMobile = ({ t, isOpen, toggleMenu, hide, lang, onChangeLang }) => (
  <nav className={cn(s.navMobile, isOpen && s.isOpen, hide ? s.hide : s.show)}>
    {isOpen ? (
      <img
        className={s.iconMenu}
        src="/images/svg/menu-close.svg"
        onClick={toggleMenu}
        alt=""
      />
    ) : (
      <img
        className={s.iconMenu}
        src="/images/svg/menu-burger.svg"
        onClick={toggleMenu}
        alt=""
      />
    )}
    <div className={s.list}>
      <NavLink
        onClick={toggleMenu}
        exact
        to="/"
        className={s.link}
        children={t('Home')}
      />
      <NavLink
        onClick={toggleMenu}
        exact
        to="/books"
        className={s.link}
        children={t('Books')}
      />
      <NavLink
        onClick={toggleMenu}
        exact
        to="/periodicals"
        className={s.link}
        children={t('periodicals')}
      />
      <NavLink
        onClick={toggleMenu}
        exact
        to="/authors"
        className={s.link}
        children={t('authors')}
      />
      {/* <NavLink
        exact
        to='/articles'
        className={s.link}
        children={t('articles')}
      /> */}
      <NavLink
        onClick={toggleMenu}
        exact
        to="/contacts"
        className={s.link}
        children={t('contacts')}
      />

      <div className={s.link}>
        <Select
          className={s.select}
          onChange={(e) => onChangeLang(e)}
          value={lang}
        >
          <SelectOption value="en" text="eng" />
          <SelectOption value="uk" text="укр" />
          <SelectOption value="ru" text="рус" />
        </Select>
      </div>
    </div>
  </nav>
);

export default withTranslation()(NavMobile);

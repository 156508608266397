import React, { useEffect, useState } from 'react';
import cn from 'classnames';
import { Helmet } from 'react-helmet';
import { withTranslation } from 'react-i18next';
import { useDataApi } from 'components';
import { Breadcrumbs, Container, Spin } from 'ui-kit';
import {
  BookDetail,
  SimularBooks,
  BookInformation,
  BookAuthor,
} from './components';
import s from './book.module.scss';

const Book = ({ i18n, match, className, scrollPosition, ...props }) => {
  const { id } = match.params;
  const [versionNumber, setVersionNumber] = useState(1);
  const urlBook = `/books/${id}?versions=true`;
  const [
    { data: book, isLoading: isLoadingBook },
    getBook,
  ] = useDataApi.useDataApi(urlBook, { book: {} });

  useEffect(() => {
    setVersionNumber(id.split('.')[id.split('.').length - 1]);
    getBook(urlBook);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [urlBook]);

  if (isClient) {
    localStorage?.setItem('publication', JSON.stringify(book));
  }

  const urlBooks = `/books?limit=${5}&offset=${0}&versions=true`;
  const [
    { data, isLoading: isLoadingBooks },
    getBooks,
  ] = useDataApi.useDataApi(urlBooks, { books: [] });

  useEffect(() => {
    getBooks(urlBooks);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [urlBooks]);

  const switchModal = (url) => {};

  const { books } = data;

  return isLoadingBook && isLoadingBooks ? (
    <Spin />
  ) : (
    <div className={cn(className, s.book)}>
      <Helmet
        title="Book Page"
        meta={[{ name: 'description', content: 'Description for Book page.' }]}
      />
      <Container>
        <Container full={false}>
          <Breadcrumbs
            paths={[
              {
                title: 'books',
                path: '/books',
              },
              {
                title: book.title,
                path: `/${book.id}`,
              },
            ]}
          />
          <BookDetail
            {...{ book, switchModal, scrollPosition, versionNumber, id }}
          />
          <SimularBooks {...{ books, match, scrollPosition }} />
          {!!book?.versions?.length && (
            <BookInformation {...{ book, versionNumber }} />
          )}
          {!!book?.authors?.length && (
            <BookAuthor {...{ book, match, scrollPosition }} />
          )}
        </Container>
      </Container>
    </div>
  );
};

export default withTranslation()(Book);

import React from 'react';
import cn from 'classnames';
import { withTranslation } from 'react-i18next';

import s from './main-title.module.scss';

const MainTitle = ({ t, texts, className }) => (
  <div className={cn(s.mainTitle, className)}>
    <div className={s.line} />
    <h2>
      <span>{t(texts[0])}</span>
      <span>{t(texts[1])}</span>
    </h2>
  </div>
);

export default withTranslation()(MainTitle);

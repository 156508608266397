import React, { useReducer, useEffect } from 'react';

import cn from 'classnames';
import s from './range.module.scss';

const Range = ({ className, options = [], fromTo = [], onChange }) => {
  const initialState = {
    ...(options.length
      ? {
          min: 0,
          max: options.length - 1,
          steps: options.length - 1,
          optionMin: options[0],
          optionMax: options.slice(-1).pop(),
        }
      : {
          optionMin: fromTo.length === 2 ? fromTo[0] : 0,
          optionMax: fromTo.slice(-1).pop(),
          min: 0,
          max: fromTo.length === 2 ? fromTo[1] - fromTo[0] : fromTo[0],
          steps: fromTo.length === 2 ? fromTo[1] - fromTo[0] : fromTo[0],
        }),
    activeLine: {
      left: 0,
      width: '100%',
    },
    left: 0,
    right: 0,
  };

  const reducer = (state, newState) => ({ ...state, ...newState });
  const [state, setState] = useReducer(reducer, initialState);
  const {
    left,
    right,
    optionMin,
    optionMax,
    min,
    max,
    steps,
    activeLine,
  } = state;

  const handleChange = (evt) => {
    const { name, value } = evt.target;

    if (name === 'min' && value < max) {
      setState({
        optionMin: options.length
          ? options[+value]
          : fromTo.length === 2
          ? fromTo[0] + +value
          : +value,
        left: `${(100 / steps) * +value}%`,
        min: +value,
        activeLine: {
          left: `${(100 / steps) * value}%`,
          width: `${(100 / steps) * (max - value)}%`,
        },
      });
    }

    if (name === 'max' && value > min) {
      setState({
        optionMax: options.length
          ? options[+value]
          : fromTo.length === 2
          ? fromTo[0] + +value
          : +value,
        right: `${(100 / steps) * (steps - +value)}%`,
        max: +value,
        activeLine: {
          left: `${(100 / steps) * min}%`,
          width: `${(100 / steps) * (value - min)}%`,
        },
      });
    }
  };

  useEffect(() => {
    if (options.length) {
      onChange([options[min], options[max]]);
    } else {
      onChange([min, max]);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [min, max]);

  const isOne =
    options.length === 1 ||
    (fromTo.length === 2 ? fromTo[1] - fromTo[0] === 1 : fromTo[0] === 1);

  const isZero = options.length <= 0 && fromTo.length === 0;

  return (
    !isZero && (
      <div
        className={cn(s.rangeWrap, className, isOne && s.isOne)}
        disabled={isOne}
      >
        {/* {label && (
        <label className={s.label}>{label}</label>
      )} */}
        <div className={s.range}>
          <div
            className={cn(s.option, s.min)}
            style={{ left }}
            children={optionMin}
          />
          {!isOne && (
            <div
              className={cn(s.option, s.max)}
              style={{ right }}
              children={optionMax}
            />
          )}
          {!isOne && <div className={s.activeLine} style={activeLine} />}
          <input
            type="range"
            value={min}
            onChange={(e) => handleChange(e)}
            name="min"
            max={steps}
            disabled={isOne}
          />
          {!isOne && (
            <input
              type="range"
              value={max}
              onChange={(e) => handleChange(e)}
              name="max"
              max={steps}
            />
          )}
        </div>
      </div>
    )
  );
};

export default Range;

import React, { useContext } from 'react';
import cn from 'classnames';
import { WidthContext } from 'contexts/width-context';
import { Issue } from 'components';
import { MainTitle, Link } from 'ui-kit';
import s from '../issue.module.scss';

function SimularIssues({ issue, issues, match, scrollPosition }) {
  const { isTabDown } = useContext(WidthContext);
  return (
    <div className={cn(s.block, s.simular)}>
      <div className={s.head}>
        <MainTitle className={s.mainTitle} texts={['Последние', 'выпуски']} />
        <Link data-v2 to={`/periodicals/${issue?.periodical?.id}`}>
          {!isTabDown && 'показать все'}
          <img src="/images/svg/arrow-left-v2.svg" alt="" />
        </Link>
      </div>
      <div className={s.issues}>
        {issues?.map((item, key) => (
          <Issue
            {...item}
            key={key}
            match={match}
            className={s.issue}
            scrollPosition={scrollPosition}
          />
        ))}
      </div>
    </div>
  );
}

export default SimularIssues;

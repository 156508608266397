import React, { useState, useContext, useEffect } from 'react';
import cn from 'classnames';
import { withTranslation } from 'react-i18next';

import { WidthContext } from 'contexts/width-context';
import { Container, Select, SelectOption, Link } from 'ui-kit';
import { Search } from 'components';
import Nav from './nav';
import NavMobile from './nav-mobile';
import s from './header.module.scss';

const Header = ({ t, i18n, onChangeLang, history }) => {
  const { language: lang } = i18n;
  const [isOpen, setIsOpen] = useState(false);
  const [isSearch, setIsSearch] = useState(false);
  // eslint-disable-next-line no-unused-vars
  const [transparent, setTransparent] = useState(0);
  const { isMob, isDesk } = useContext(WidthContext);
  useEffect(() => {
    const onScroll = () => {
      setTransparent(window.scrollY / (window.innerHeight / 4));
    };
    window.addEventListener('scroll', onScroll);
    return () => {
      window.addEventListener('scroll', onScroll);
    };
  }, []);

  const toggleMenu = () => {
    setIsOpen(!isOpen);
  };

  const isStartSearch = (value) => {
    setIsSearch(value);
  };

  useEffect(() => {
    if (isClient && isSearch) {
      document.body.style.overflow = 'hidden';
      document.body.style.position = 'relative';
    }

    if (isClient) {
      document.body.style.overflow = 'auto';
      document.body.style.position = 'static';
    }
  }, [isSearch]);

  // const style = {
  //   boxShadow: transparent == 0
  //   ? 'none'
  //   : `0 0 10px 0 rgba(9, 35, 84, ${transparent >= 1 ? 0.12 : transparent * 0.12 }), 0 0 5px 0 rgba(9, 35, 84, ${transparent >= 1 ? 0.12 : transparent * 0.12 })`
  // }

  return (
    <header className={s.header}>
      <div className={cn(s.background, isSearch ? s.show : s.hide)} />
      <Container full={false} className={s.container}>
        <Link to="/" className={cn(s.logo, isMob && isSearch && s.hide)}>
          <img src="/images/svg/logo.svg" alt="lifesource logo" />
          {(isMob || isDesk) && (
            <div className={s.siteName}>
              <h4>{t('lifesource')}</h4>
              <div>{t('christian-publishing')}</div>
            </div>
          )}
        </Link>
        <NavMobile
          {...{ isOpen, lang, onChangeLang, toggleMenu }}
          hide={isMob && isSearch}
        />
        <Nav hide={isSearch} />
        <Search
          isSearch={isSearch}
          className={s.search}
          history={history}
          isStartSearch={isStartSearch}
        />
        {!isMob && (
          <Select
            className={s.select}
            onChange={(e) => onChangeLang(e)}
            value={lang}
          >
            <SelectOption value="en" text="eng" />
            <SelectOption value="uk" text="укр" />
            <SelectOption value="ru" text="рус" />
          </Select>
        )}
      </Container>
    </header>
  );
};

export default withTranslation()(Header);

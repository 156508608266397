import React from 'react';
import { withTranslation } from 'react-i18next';
import { Link } from 'ui-kit';
import { LazyLoadImage } from 'react-lazy-load-image-component';
import s from './author.module.scss';

const Author = ({
  t,
  i18n,
  firstName,
  lastName,
  photo,
  type,
  id,
  scrollPosition,
}) => {
  const lang = i18n.language;
  return (
    <Link to={`/authors/${id}`} className={s.author}>
      <LazyLoadImage
        className={s.photo}
        wrapperClassName={s.photoW}
        scrollPosition={scrollPosition}
        placeholderSrc="/images/default-author.png"
        src={photo || '/images/default-author.png'}
        alt=""
      />
      <div className={s.name}>
        {firstName[lang]}
        <br />
        {lastName[lang]}
      </div>
      <div className={s.proffesion}>{t(type)}</div>
      <div className={s.square} />
    </Link>
  );
};

export default withTranslation()(Author);

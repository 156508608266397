import React, { useEffect, useReducer } from 'react';
import cn from 'classnames';
import { withTranslation } from 'react-i18next';

import { Input, Link } from 'ui-kit';

import { useAxios } from '@life/spa/utils/axios.js';

import s from './search.module.scss';

const searchReducer = (state, action) => {
  switch (action.type) {
    case 'UPDATE_SEARCH':
      return {
        ...state,
        ...action.payload,
      };
    default:
  }
};

const Search = ({ t, i18n, className, history, isStartSearch, isSearch }) => {
  const initialSearch = {
    isShow: false,
    value: '',
  };

  const [state, dispatch] = useReducer(searchReducer, initialSearch);

  const { isShow, value } = state;

  const DEFAUTL_BOOK_COVER = '/images/svg/defaultBook.svg';
  const DEFAUTL_AUTHOR_COVER = '/images/default-author.png';

  const [{ data = {} }, getData] = useAxios(
    {
      url: '/q',
      method: 'GET',
    },
    {
      manual: true,
    }
  );

  const { matched = [], other = [] } = data;

  useEffect(() => {
    if (value.length > 2) {
      getData({
        params: {
          limit: 50,
          text: value,
        },
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [value]);

  const onChange = ({ target }) => {
    const { value } = target;
    dispatch({
      type: 'UPDATE_SEARCH',
      payload: {
        value,
        isShow: value.length >= 3,
      },
    });
  };

  const onClean = () => {
    dispatch({
      type: 'UPDATE_SEARCH',
      payload: {
        value: '',
        isShow: false,
      },
    });
    isStartSearch(false);
  };

  const initSearch = () => {
    isStartSearch(true);
  };

  const handlePressEsc = ({ keyCode }) => {
    if (keyCode === 27) {
      onClean();
    }
  };

  useEffect(() => {
    document.addEventListener('keydown', handlePressEsc, false);

    return () => {
      document.removeEventListener('keydown', handlePressEsc, false);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const Resutls = ({ results }) => {
    const escapeRegExp = (str = '') =>
      str.replace(/([.?*+^$[\]\\(){}|-])/g, '\\$1');

    const getHighlightedText = (text, higlight) => {
      const patt = new RegExp(`(${escapeRegExp(higlight)})`, 'i');
      const parts = String(text).split(patt);

      return parts.map((part, i) => {
        const isMatch = part.toLowerCase() === higlight.toLowerCase();
        return isMatch ? <span key={i}>{part}</span> : part;
      });
    };

    return results.map(({ title, subtitle, link, thumbnail }, i) => {
      const isAuthor = link.includes('authors');
      const src = isAuthor ? DEFAUTL_AUTHOR_COVER : DEFAUTL_BOOK_COVER;

      return (
        <Link
          to={link}
          key={title + i}
          className={s.suggestion}
          onClick={onClean}
        >
          <div className={cn(s.image, isAuthor && s.rounded)}>
            <img
              src={thumbnail || src}
              alt={title}
              onError={({ currentTarget }) => {
                currentTarget.src = src;
              }}
            />
          </div>
          <div className={s.info}>
            <div className={s.title}>{getHighlightedText(title, value)}</div>
            <div className={s.subTitle}>
              {getHighlightedText(subtitle, value)}
            </div>
          </div>
          <img className={s.arrow} src="/images/svg/arrow-left-v2.svg" alt="" />
        </Link>
      );
    });
  };

  return (
    <div className={cn(s.search, className, isSearch && s.active)}>
      <label className={s.label} htmlFor="search" onClick={initSearch}>
        <img
          className={s.icon}
          src="/images/svg/search.svg"
          alt="search icon"
        />
      </label>
      <Input
        id="search"
        type="search"
        autoComplete="off"
        placeholder={`${t('search')}..`}
        value={value}
        className={s.input}
        onChange={onChange}
      >
        <div
          onClick={onClean}
          className={cn(s.close, isSearch ? s.show : s.hide)}
        >
          <img
            alt="cross"
            src="/images/svg/cross@1x.svg"
            srcSet="/images/svg/cross@1x.svg 1x, /images/svg/cross@2x.svg 2x"
          />
        </div>
      </Input>
      <div className={cn(s.suggestions, isShow && s.show)}>
        {isShow &&
          (matched?.length || other?.length ? (
            <>
              <div className={s.category}>
                {matched.length && <Resutls results={matched} />}
              </div>
              {!!other.length && (
                <div className={s.category}>
                  <div className={s.type}>Еще результаты</div>
                  <Resutls results={other} />
                </div>
              )}
            </>
          ) : (
            <div className={s.category}>
              <div className={cn(s.type, s.noMatch)}>
                {t('no matches for')} «<span>{value}</span>»
              </div>
            </div>
          ))}
      </div>
    </div>
  );
};

export default withTranslation()(Search);

import React from 'react';
import { withTranslation } from 'react-i18next';

import { Range } from 'ui-kit';
import s from './filters.module.scss';

const Filters = ({ filters }) => {
  return (
    <div className={s.filters}>
      {filters.map(({ name, field, ...filter }) => {
        return (
          <div className={s.filter}>
            <div className={s.title}>{name}:</div>
            <Range
              options={[2010, 2011, 2012, 2013, 2014, 2015]}
              onChange={() => {}}
              className={s.range}
            />
          </div>
        );
      })}
    </div>
  );
};

export default withTranslation()(Filters);

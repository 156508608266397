/* eslint-disable react/no-danger */
import React from 'react';

// TODO: array of datas link to images with all params(attrs) and render in head compoennt
// const links, meta, images, og arrays
// const headData = [...links, ...meta, ...images, ...og];

const Html = ({ lang, children, head, css, context }) => (
  <html lang={lang} prefix="og: http://ogp.me/ns#">
    <head>
      <meta charSet="utf-8" />
      {/* {head.title.toString().replace(/ data-react-helmet="true"/g, '')}
        {head.meta.toString().replace(/ data-react-helmet="true"/g, '')}
      {head.link.toString().replace(/ data-react-helmet="true"/g, '')} */}
      {head.title.toComponent()}
      {head.meta.toComponent()}
      {head.link.toComponent()}

      {/* this lines ↓ correct only for home page? */}
      {/* {langs.map((lang, index) => (
        <link key={index} rel="alternate" href={`${HOST}/${lang}/`} hreflang={lang} />
      ))} */}
      {/* generics */}
      {/* <link rel="shortcut icon" href="/favicon.ico" /> */}

      <link rel="icon" href="/images/favicon/16.png" sizes="16x16" />
      <link rel="icon" href="/images/favicon/32.png" sizes="32x32" />
      <link rel="icon" href="/images/favicon/57.png" sizes="57x57" />
      {/* <link rel="icon" href="/images/favicon/76.png" sizes="76x76" /> */}
      <link rel="icon" href="/images/favicon/96.png" sizes="96x96" />
      <link rel="icon" href="/images/favicon/128.png" sizes="128x128" />
      <link rel="icon" href="/images/favicon/192.png" sizes="192x192" />
      <link rel="icon" href="/images/favicon/228.png" sizes="228x228" />

      {/* Android */}
      <link
        rel="shortcut icon"
        sizes="196x196"
        href="/images/favicon/196.png"
      />

      {/* iOS */}
      <link
        rel="apple-touch-icon"
        href="/images/favicon/120.png"
        sizes="120x120"
      />
      <link
        rel="apple-touch-icon"
        href="/images/favicon/152.png"
        sizes="152x152"
      />
      <link
        rel="apple-touch-icon"
        href="/images/favicon/180.png"
        sizes="180x180"
      />

      <meta
        name="viewport"
        content="initial-scale=1.0, width=device-width, maximum-scale=2, viewport-fit=cover"
      />
      <link rel="manifest" href="/manifest.json" />
      <meta name="theme-color" content="#7ACCE5" />
      {/* <link rel="icon" sizes="192x192" href="/images/mannifest/logo-pwa-192.png" /> */}
      {/* <link rel="apple-touch-icon" sizes="152x152" href="/images/mannifest/logo-pwa-152.png" /> */}
      {/* <link rel="apple-touch-icon" sizes="180x180" href="touch-icon-iphone-retina.png" />
      <link rel="apple-touch-icon" sizes="167x167" href="touch-icon-ipad-retina.png" /> */}
      <link rel="preconnect" href={apiUrl} />
      <link rel="dns-prefetch" href={apiUrl} />
      <link
        rel="preload"
        href="/fonts/SF/SF-UI-Regular.otf"
        as="font"
        crossOrigin="anonymous"
      />
      <link
        rel="preload"
        href="/fonts/SF/SF-UI-Bold.otf"
        as="font"
        crossOrigin="anonymous"
      />
      <link
        rel="preload"
        href="/fonts/SF/SF-UI-Light.otf"
        as="font"
        crossOrigin="anonymous"
      />
      {/* iPhone X, Xs (1125px x 2436px) */}
      {/* from: https://medium.com/appscope/adding-custom-ios-splash-screens-to-your-progressive-web-app-41a9b18bdca3 */}
      <link
        rel="apple-touch-startup-image"
        media="(device-width: 375px) and (device-height: 812px) and (-webkit-device-pixel-ratio: 3)"
        href="/apple-launch-1125x2436.png"
      />
      <meta name="apple-mobile-web-app-capable" content="yes" />

      {/* <meta name="robots" content="noindex" /> */}
      <style>{[...css].join('')}</style>
      {/* <link rel="stylesheet" type="text/css" charSet="UTF-8" href="https://cdnjs.cloudflare.com/ajax/libs/slick-carousel/1.6.0/slick.min.css" /> */}
      {/* <link rel="stylesheet" type="text/css" href="https://cdnjs.cloudflare.com/ajax/libs/slick-carousel/1.6.0/slick-theme.min.css" /> */}
      <style>
        {`
          .Toastify__toast-container {
            z-index: 9999;
            -webkit-transform: translate3d(0, 0, 9999px);
            position: fixed;
            padding: 4px;
            width: 320px;
            box-sizing: border-box;
            color: #fff; }
            .Toastify__toast-container--top-left {
              top: 1em;
              left: 1em; }
            .Toastify__toast-container--top-center {
              top: 1em;
              left: 50%;
              margin-left: -160px; }
            .Toastify__toast-container--top-right {
              top: 1em;
              right: 1em; }
            .Toastify__toast-container--bottom-left {
              bottom: 1em;
              left: 1em; }
            .Toastify__toast-container--bottom-center {
              bottom: 1em;
              left: 50%;
              margin-left: -160px; }
            .Toastify__toast-container--bottom-right {
              bottom: 1em;
              right: 1em; }

          @media only screen and (max-width: 480px) {
            .Toastify__toast-container {
              width: 100vw;
              padding: 0;
              left: 0;
              margin: 0; }
              .Toastify__toast-container--top-left, .Toastify__toast-container--top-center, .Toastify__toast-container--top-right {
                top: 0; }
              .Toastify__toast-container--bottom-left, .Toastify__toast-container--bottom-center, .Toastify__toast-container--bottom-right {
                bottom: 0; }
              .Toastify__toast-container--rtl {
                right: 0;
                left: initial; } }

          .Toastify__toast {
            position: relative;
            min-height: 64px;
            box-sizing: border-box;
            margin-bottom: 1rem;
            padding: 8px;
            border-radius: 1px;
            box-shadow: 0 1px 10px 0 rgba(0, 0, 0, 0.1), 0 2px 15px 0 rgba(0, 0, 0, 0.05);
            display: -ms-flexbox;
            display: flex;
            -ms-flex-pack: justify;
                justify-content: space-between;
            max-height: 800px;
            overflow: hidden;
            font-family: sans-serif;
            cursor: pointer;
            direction: ltr; }
            .Toastify__toast--rtl {
              direction: rtl; }
            .Toastify__toast--default {
              background: #fff;
              color: #aaa; }
            .Toastify__toast--info {
              background: #3498db; }
            .Toastify__toast--success {
              background: #07bc0c; }
            .Toastify__toast--warning {
              background: #f1c40f; }
            .Toastify__toast--error {
              background: #e74c3c; }
            .Toastify__toast-body {
              margin: auto 0;
              -ms-flex: 1;
                  flex: 1; }

          @media only screen and (max-width: 480px) {
            .Toastify__toast {
              margin-bottom: 0; } }

          .Toastify__close-button {
            color: #fff;
            font-weight: bold;
            font-size: 14px;
            background: transparent;
            outline: none;
            border: none;
            padding: 0;
            cursor: pointer;
            opacity: 0.7;
            transition: 0.3s ease;
            -ms-flex-item-align: start;
                align-self: flex-start; }
            .Toastify__close-button--default {
              color: #000;
              opacity: 0.3; }
            .Toastify__close-button:hover, .Toastify__close-button:focus {
              opacity: 1; }

          @keyframes Toastify__trackProgress {
            0% {
              transform: scaleX(1); }
            100% {
              transform: scaleX(0); } }

          .Toastify__progress-bar {
            position: absolute;
            bottom: 0;
            left: 0;
            width: 100%;
            height: 5px;
            z-index: 9999;
            opacity: 0.7;
            background-color: rgba(255, 255, 255, 0.7);
            transform-origin: left; }
            .Toastify__progress-bar--animated {
              animation: Toastify__trackProgress linear 1 forwards; }
            .Toastify__progress-bar--controlled {
              transition: transform .2s; }
            .Toastify__progress-bar--rtl {
              right: 0;
              left: initial;
              transform-origin: right; }
            .Toastify__progress-bar--default {
              background: linear-gradient(to right, #4cd964, #5ac8fa, #007aff, #34aadc, #5856d6, #ff2d55); }

          @keyframes Toastify__bounceInRight {
            from,
            60%,
            75%,
            90%,
            to {
              animation-timing-function: cubic-bezier(0.215, 0.61, 0.355, 1); }
            from {
              opacity: 0;
              transform: translate3d(3000px, 0, 0); }
            60% {
              opacity: 1;
              transform: translate3d(-25px, 0, 0); }
            75% {
              transform: translate3d(10px, 0, 0); }
            90% {
              transform: translate3d(-5px, 0, 0); }
            to {
              transform: none; } }

          @keyframes Toastify__bounceOutRight {
            20% {
              opacity: 1;
              transform: translate3d(-20px, 0, 0); }
            to {
              opacity: 0;
              transform: translate3d(2000px, 0, 0); } }

          @keyframes Toastify__bounceInLeft {
            from,
            60%,
            75%,
            90%,
            to {
              animation-timing-function: cubic-bezier(0.215, 0.61, 0.355, 1); }
            0% {
              opacity: 0;
              transform: translate3d(-3000px, 0, 0); }
            60% {
              opacity: 1;
              transform: translate3d(25px, 0, 0); }
            75% {
              transform: translate3d(-10px, 0, 0); }
            90% {
              transform: translate3d(5px, 0, 0); }
            to {
              transform: none; } }

          @keyframes Toastify__bounceOutLeft {
            20% {
              opacity: 1;
              transform: translate3d(20px, 0, 0); }
            to {
              opacity: 0;
              transform: translate3d(-2000px, 0, 0); } }

          @keyframes Toastify__bounceInUp {
            from,
            60%,
            75%,
            90%,
            to {
              animation-timing-function: cubic-bezier(0.215, 0.61, 0.355, 1); }
            from {
              opacity: 0;
              transform: translate3d(0, 3000px, 0); }
            60% {
              opacity: 1;
              transform: translate3d(0, -20px, 0); }
            75% {
              transform: translate3d(0, 10px, 0); }
            90% {
              transform: translate3d(0, -5px, 0); }
            to {
              transform: translate3d(0, 0, 0); } }

          @keyframes Toastify__bounceOutUp {
            20% {
              transform: translate3d(0, -10px, 0); }
            40%,
            45% {
              opacity: 1;
              transform: translate3d(0, 20px, 0); }
            to {
              opacity: 0;
              transform: translate3d(0, -2000px, 0); } }

          @keyframes Toastify__bounceInDown {
            from,
            60%,
            75%,
            90%,
            to {
              animation-timing-function: cubic-bezier(0.215, 0.61, 0.355, 1); }
            0% {
              opacity: 0;
              transform: translate3d(0, -3000px, 0); }
            60% {
              opacity: 1;
              transform: translate3d(0, 25px, 0); }
            75% {
              transform: translate3d(0, -10px, 0); }
            90% {
              transform: translate3d(0, 5px, 0); }
            to {
              transform: none; } }

          @keyframes Toastify__bounceOutDown {
            20% {
              transform: translate3d(0, 10px, 0); }
            40%,
            45% {
              opacity: 1;
              transform: translate3d(0, -20px, 0); }
            to {
              opacity: 0;
              transform: translate3d(0, 2000px, 0); } }

          .Toastify__bounce-enter--top-left, .Toastify__bounce-enter--bottom-left {
            animation-name: Toastify__bounceInLeft; }

          .Toastify__bounce-enter--top-right, .Toastify__bounce-enter--bottom-right {
            animation-name: Toastify__bounceInRight; }

          .Toastify__bounce-enter--top-center {
            animation-name: Toastify__bounceInDown; }

          .Toastify__bounce-enter--bottom-center {
            animation-name: Toastify__bounceInUp; }

          .Toastify__bounce-exit--top-left, .Toastify__bounce-exit--bottom-left {
            animation-name: Toastify__bounceOutLeft; }

          .Toastify__bounce-exit--top-right, .Toastify__bounce-exit--bottom-right {
            animation-name: Toastify__bounceOutRight; }

          .Toastify__bounce-exit--top-center {
            animation-name: Toastify__bounceOutUp; }

          .Toastify__bounce-exit--bottom-center {
            animation-name: Toastify__bounceOutDown; }

          @keyframes Toastify__zoomIn {
            from {
              opacity: 0;
              transform: scale3d(0.3, 0.3, 0.3); }
            50% {
              opacity: 1; } }

          @keyframes Toastify__zoomOut {
            from {
              opacity: 1; }
            50% {
              opacity: 0;
              transform: scale3d(0.3, 0.3, 0.3); }
            to {
              opacity: 0; } }

          .Toastify__zoom-enter {
            animation-name: Toastify__zoomIn; }

          .Toastify__zoom-exit {
            animation-name: Toastify__zoomOut; }

          @keyframes Toastify__flipIn {
            from {
              transform: perspective(400px) rotate3d(1, 0, 0, 90deg);
              animation-timing-function: ease-in;
              opacity: 0; }
            40% {
              transform: perspective(400px) rotate3d(1, 0, 0, -20deg);
              animation-timing-function: ease-in; }
            60% {
              transform: perspective(400px) rotate3d(1, 0, 0, 10deg);
              opacity: 1; }
            80% {
              transform: perspective(400px) rotate3d(1, 0, 0, -5deg); }
            to {
              transform: perspective(400px); } }

          @keyframes Toastify__flipOut {
            from {
              transform: perspective(400px); }
            30% {
              transform: perspective(400px) rotate3d(1, 0, 0, -20deg);
              opacity: 1; }
            to {
              transform: perspective(400px) rotate3d(1, 0, 0, 90deg);
              opacity: 0; } }

          .Toastify__flip-enter {
            animation-name: Toastify__flipIn; }

          .Toastify__flip-exit {
            animation-name: Toastify__flipOut; }

          @keyframes Toastify__slideInRight {
            from {
              transform: translate3d(110%, 0, 0);
              visibility: visible; }
            to {
              transform: translate3d(0, 0, 0); } }

          @keyframes Toastify__slideInLeft {
            from {
              transform: translate3d(-110%, 0, 0);
              visibility: visible; }
            to {
              transform: translate3d(0, 0, 0); } }

          @keyframes Toastify__slideInUp {
            from {
              transform: translate3d(0, 110%, 0);
              visibility: visible; }
            to {
              transform: translate3d(0, 0, 0); } }

          @keyframes Toastify__slideInDown {
            from {
              transform: translate3d(0, -110%, 0);
              visibility: visible; }
            to {
              transform: translate3d(0, 0, 0); } }

          @keyframes Toastify__slideOutRight {
            from {
              transform: translate3d(0, 0, 0); }
            to {
              visibility: hidden;
              transform: translate3d(110%, 0, 0); } }

          @keyframes Toastify__slideOutLeft {
            from {
              transform: translate3d(0, 0, 0); }
            to {
              visibility: hidden;
              transform: translate3d(-110%, 0, 0); } }

          @keyframes Toastify__slideOutDown {
            from {
              transform: translate3d(0, 0, 0); }
            to {
              visibility: hidden;
              transform: translate3d(0, 500px, 0); } }

          @keyframes Toastify__slideOutUp {
            from {
              transform: translate3d(0, 0, 0); }
            to {
              visibility: hidden;
              transform: translate3d(0, -500px, 0); } }

          .Toastify__slide-enter--top-left, .Toastify__slide-enter--bottom-left {
            animation-name: Toastify__slideInLeft; }

          .Toastify__slide-enter--top-right, .Toastify__slide-enter--bottom-right {
            animation-name: Toastify__slideInRight; }

          .Toastify__slide-enter--top-center {
            animation-name: Toastify__slideInDown; }

          .Toastify__slide-enter--bottom-center {
            animation-name: Toastify__slideInUp; }

          .Toastify__slide-exit--top-left, .Toastify__slide-exit--bottom-left {
            animation-name: Toastify__slideOutLeft; }

          .Toastify__slide-exit--top-right, .Toastify__slide-exit--bottom-right {
            animation-name: Toastify__slideOutRight; }

          .Toastify__slide-exit--top-center {
            animation-name: Toastify__slideOutUp; }

          .Toastify__slide-exit--bottom-center {
            animation-name: Toastify__slideOutDown; }

          /*# sourceMappingURL=ReactToastify.css.map */
        `}

        {`
          .slick-list,.slick-slider,.slick-track{position:relative;display:block}.slick-loading .slick-slide,.slick-loading .slick-track{visibility:hidden}.slick-slider{box-sizing:border-box;-webkit-user-select:none;-moz-user-select:none;-ms-user-select:none;user-select:none;-webkit-touch-callout:none;-khtml-user-select:none;-ms-touch-action:pan-y;touch-action:pan-y;-webkit-tap-highlight-color:transparent}.slick-list{overflow:hidden;margin:0;padding:0}.slick-list:focus{outline:0}.slick-list.dragging{cursor:pointer;cursor:hand}.slick-slider .slick-list,.slick-slider .slick-track{-webkit-transform:translate3d(0,0,0);-moz-transform:translate3d(0,0,0);-ms-transform:translate3d(0,0,0);-o-transform:translate3d(0,0,0);transform:translate3d(0,0,0)}.slick-track{top:0;left:0}.slick-track:after,.slick-track:before{display:table;content:''}.slick-track:after{clear:both}.slick-slide{display:none;float:left;height:100%;min-height:1px}[dir=rtl] .slick-slide{float:right}.slick-slide img{display:block}.slick-slide.slick-loading img{display:none}.slick-slide.dragging img{pointer-events:none}.slick-initialized .slick-slide{display:block}.slick-vertical .slick-slide{display:block;height:auto;border:1px solid transparent}.slick-arrow.slick-hidden{display:none}/*# sourceMappingURL=slick.min.css.map */
        `}
        {`
          .ReactCollapse--collapse {
            transition: height 250ms;
          }

          .slick-list {
            overflow: hidden;
          }

          .slick-list * {
            outline: none;
          }

          .slick-track {
            display: flex;
            min-height: 100%;
          }

          .slick-slide {
            min-height: 100%;
          }

          .slick-slide > div {
            width: 100%;
            height: 100%;
          }

          .slick-slide.slick-active {
            position: relative;
            z-index: 2;
          }
        `}
        {`
          .react-calendar {
            width: 350px;
            max-width: 100%;
            background: white;
            border: 1px solid #a0a096;
            font-family: Arial, Helvetica, sans-serif;
            line-height: 1.125em;
          }
          .react-calendar button {
            margin: 0;
            border: 0;
            outline: none;
          }
          .react-calendar button:enabled:hover {
            cursor: pointer;
          }
          .react-calendar__navigation {
            height: 44px;
            margin-bottom: 1em;
          }
          .react-calendar__navigation button {
            min-width: 44px;
            background: none;
          }
          .react-calendar__navigation button:enabled:hover,
          .react-calendar__navigation button:enabled:focus {
            background-color: #e6e6e6;
          }
          .react-calendar__navigation button[disabled] {
            background-color: #f0f0f0;
          }
          .react-calendar__month-view__weekdays {
            text-align: center;
            text-transform: uppercase;
            font-weight: bold;
            font-size: 0.75em;
          }
          .react-calendar__month-view__weekdays__weekday {
            padding: 0.5em;
          }
          .react-calendar__month-view__weekNumbers {
            font-weight: bold;
          }
          .react-calendar__month-view__weekNumbers .react-calendar__tile {
            display: flex;
            align-items: center;
            justify-content: center;
            font-size: 0.75em;
            padding: calc(0.75em / 0.75) calc(0.5em / 0.75);
          }
          .react-calendar__month-view__days__day--weekend {
            color: #d10000;
          }
          .react-calendar__month-view__days__day--neighboringMonth {
            color: #757575;
          }
          .react-calendar__year-view .react-calendar__tile,
          .react-calendar__decade-view .react-calendar__tile,
          .react-calendar__century-view .react-calendar__tile {
            padding: 2em 0.5em;
          }
          .react-calendar__tile {
            max-width: 100%;
            text-align: center;
            padding: 0.75em 0.5em;
            background: none;
          }
          .react-calendar__tile:disabled {
            background-color: #f0f0f0;
          }
          .react-calendar__tile:enabled:hover,
          .react-calendar__tile:enabled:focus {
            background-color: #e6e6e6;
          }
          .react-calendar__tile--hasActive {
            background: #76baff;
          }
          .react-calendar__tile--hasActive:enabled:hover,
          .react-calendar__tile--hasActive:enabled:focus {
            background: #a9d4ff;
          }
          .react-calendar__tile--active {
            background: #006edc;
            color: white;
          }
          .react-calendar__tile--active:enabled:hover,
          .react-calendar__tile--active:enabled:focus {
            background: #1087ff;
          }
          .react-calendar--selectRange .react-calendar__tile--hover {
            background-color: #e6e6e6;
          }
        `}
        {`
          .react-date-picker {
            display: inline-flex;
            position: relative;
          }
          .react-date-picker--disabled {
            background-color: #f0f0f0;
            color: #6d6d6d;
          }
          .react-date-picker__wrapper {
            display: flex;
          }
          .react-date-picker__inputGroup {
            min-width: calc((4px * 3) +  0.54em * 8  +  0.217em * 2);
            flex-grow: 1;
            display: flex;
            padding: 0 2px;
            align-items: baseline;
            box-sizing: content-box;
          }
          .react-date-picker__inputGroup__divider {
            padding: 1px 0;
            white-space: pre;
          }
          .react-date-picker__inputGroup__input {
            min-width: 0.54em;
            height: 100%;
            position: relative;
            padding: 0 4px;
            border: 0;
            background: none;
            font: inherit;
            box-sizing: content-box;
            -moz-appearance: textfield;
          }
          .react-date-picker__inputGroup__input::-webkit-outer-spin-button,
          .react-date-picker__inputGroup__input::-webkit-inner-spin-button {
            -webkit-appearance: none;
            margin: 0;
          }
          .react-date-picker__inputGroup__input:invalid {
            background: rgba(255, 0, 0, 0.1);
          }
          .react-date-picker__inputGroup__input--hasLeadingZero {
            margin-left: -0.54em;
            padding-left: calc(1px +  0.54em);
          }
          .react-date-picker__button {
            border: 0;
            background: transparent;
            padding: 4px 6px;
          }
          .react-date-picker__button:enabled {
            cursor: pointer;
          }
          .react-date-picker__button:enabled:hover .react-date-picker__button__icon,
          .react-date-picker__button:enabled:focus .react-date-picker__button__icon {
            stroke: #0078d7;
          }
          .react-date-picker__button:disabled .react-date-picker__button__icon {
            stroke: #6d6d6d;
          }
          .react-date-picker__button svg {
            display: inherit;
          }
          .react-date-picker__calendar {
            background-color: white;
            width: 350px;
            max-width: 100vw;
            position: absolute;
            top: 100%;
            left: 0;
            z-index: 1;
          }
          .react-date-picker__calendar--closed {
            display: none;
          }
          .react-date-picker__calendar .react-calendar {
            border-width: thin;
          }

          .react-date-picker__clear-button {
            display: none;
          }
          .react-date-picker__calendar-button {
            display: none;
          }
        `}
        {`
          .lazy-load-image-background.blur {
            filter: blur(1px);
          }
          .lazy-load-image-background.blur::after {
            filter: blur(2px);
          }
          .lazy-load-image-background.blur.lazy-load-image-loaded {
            filter: blur(0);
            transition: filter .3s;
          }
          
          .lazy-load-image-background.blur > img {
            opacity: 0;
          }
          
          .lazy-load-image-background.blur.lazy-load-image-loaded > img {
            opacity: 1;
            transition: opacity .3s;
          }
        `}
      </style>
    </head>
    <body>
      <div id="app" dangerouslySetInnerHTML={{ __html: children || '' }} />
      <script src="/bundle.js" async />
      <script
        id="state"
        dangerouslySetInnerHTML={{
          __html: `window.__SSR_STATE__=${JSON.stringify(context)};`,
        }}
      />
    </body>
  </html>
);

export default Html;

/* eslint-disable no-console */
import React from 'react';
import ReactDOM from 'react-dom';
import { Router } from 'react-router-dom';
import { I18nextProvider } from 'react-i18next';
import { createBrowserHistory } from 'history';
import i18n from 'i18n';

import { WidthProvider } from '@life/spa/contexts/width-context';
import { App } from '@life/spa/components';
import { useAxios } from '@life/spa/utils';

import '@life/spa/styles/index.scss';

if (window?.__SSR_STATE__?.data) {
  useAxios.loadCache(JSON.parse(window.__SSR_STATE__.data));
  delete window.__SSR_STATE__;
}

if ('serviceWorker' in navigator) {
  window.addEventListener('load', () => {
    navigator.serviceWorker
      .register('/sw.js')
      .then(
        (registration) => {
          // Registration was successful
          console.log(
            'ServiceWorker registration successful with scope: ',
            registration
          );
        },
        (err) => {
          // registration failed :(
          console.log('ServiceWorker registration failed: ', err);
        }
      )
      .catch(console.error);
  });
} else {
  console.log('service worker is not supported');
}

const lang = 'ru';
i18n.changeLanguage(lang);

const history = createBrowserHistory();
const container = document.getElementById('root');

ReactDOM.hydrate(
  <WidthProvider>
    <Router history={history}>
      <I18nextProvider>
        <App />
      </I18nextProvider>
    </Router>
  </WidthProvider>,
  container
);

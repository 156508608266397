import React from 'react';
// import { bool, number, string } from 'prop-types'

import cn from 'classnames';
import s from './tabs.module.scss';

const Tab = ({
  icon,
  text,
  counter,
  isActive,
  tabIndex,
  className,
  onChange,
  changeActiveTab,
  disabled,
  ...props
}) => {
  const classNames = cn(s.tab, isActive && s.active, className);
  return (
    <div
      {...props}
      disabled={disabled}
      className={classNames}
      onClick={
        disabled
          ? () => {}
          : () => {
              changeActiveTab(tabIndex);
              onChange();
            }
      }
    >
      <div className={s.tabText}>
        <img src={`/images/svg/${icon}`} alt="" />
        {text}
      </div>
      {isActive && <div className={s.line} />}
      <div className={cn(s.auxiliary, s.left)} />
      <div className={cn(s.auxiliary, s.right)} />
    </div>
  );
};

// Tab.propTypes = {
//   isActive: bool,
//   tabIndex: number,
//   text: string.isRequired,
// }

Tab.defaultProps = {
  isActive: false,
};

export default Tab;

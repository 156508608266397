import React from 'react';
import cn from 'classnames';

import { Link } from 'ui-kit';
import s from './button.module.scss';

const Button = ({
  iconLeft,
  iconRight,
  to,
  text,
  color,
  children,
  disabled,
  className,
  ...props
}) => {
  const classes = cn(s.button, to && s.link, s[color], className);
  return to ? (
    <Link to={to} className={classes} {...props}>
      {iconLeft && (
        <img
          alt="iconLeft"
          src={`/images/svg/${iconLeft}.svg`}
          className={s.iconLeft}
        />
      )}
      {text || children}
      {iconRight && (
        <img
          alt="iconRight"
          src={`/images/svg/${iconRight}.svg`}
          className={s.iconRight}
        />
      )}
    </Link>
  ) : (
    <button className={classes} disabled={disabled} {...props}>
      {iconLeft && (
        <img
          alt="iconLeft"
          src={`/images/svg/${iconLeft}.svg`}
          className={s.iconLeft}
        />
      )}
      {text || children}
      {iconRight && (
        <img
          alt="iconRight"
          src={`/images/svg/${iconRight}.svg`}
          className={s.iconRight}
        />
      )}
    </button>
  );
};

export default Button;

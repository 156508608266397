import { useState, useEffect } from 'react';

import { useAxios } from '../utils/axios';

const getSuggestions = (params = {}, method = 'GET') =>
  request({
    url: '/suggestions',
    params: {
      limit: 5,
      offset: 0,
      ...params,
    },
    method,
  });

const search = (params = {}, method = 'GET') =>
  request({
    url: '/q',
    params: {
      limit: 5,
      offset: 0,
      ...params,
    },
    method,
  });

const getBanners = (params = {}, method = 'GET') =>
  request({
    url: '/banners',
    params,
    method,
  });

const getBooks = (params = {}, method = 'GET') =>
  request({
    url: '/books',
    params: {
      versions: true,
      ...params,
    },
    method,
  });

const getPeriodicals = (params = {}, method = 'GET') =>
  request({
    url: '/periodicals',
    params: {
      ...params,
    },
    method,
  });

const getIssues = (params = {}, method = 'GET') =>
  request({
    url: '/periodicals/issues',
    params: {
      public: true,
      ...params,
    },
    method,
  });

const getPeriodicalIssues = ({ id, ...params }, method = 'GET') =>
  request({
    url: `/periodicals/${id}/issues`,
    params: {
      ...params,
    },
    method,
  });

const getIssue = ({ id, issueId, ...params }, method = 'GET') =>
  request({
    url: `/periodicals/${id}/issues/${issueId}`,
    params: {
      ...params,
    },
    method,
  });

const getCategories = (params = {}, method = 'GET') =>
  request({
    url: '/categories',
    params: {
      limit: 0,
      offset: 0,
      ...params,
    },
    method,
  });

const request = (props) => {
  const { url, method } = props;
  const [params, setParams] = useState(props.params);

  const [{ error, loading, data = {} }, get] = useAxios({
    url,
    method,
  });

  useEffect(() => {
    get(method === 'GET' ? { params } : { data: params });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [params]);

  return [
    {
      data,
      params,
      isLoading: loading,
      error: error?.message,
      isError: Boolean(error),
    },
    setParams,
  ];
};

const useDataApi = (initialUrl, initialData) => {
  const [url, setUrl] = useState(initialUrl);

  const [{ error, loading, data = initialData || {} }, getData] = useAxios({
    url,
    method: 'GET',
  });

  useEffect(() => {
    getData(url);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [url]);

  return [{ data, isLoading: loading, isError: Boolean(error) }, setUrl];
};

export default {
  search,
  getSuggestions,
  useDataApi,
  getBanners,
  getBooks,
  getCategories,
  getPeriodicals,
  getIssues,
  getIssue,
  getPeriodicalIssues,
};

import React from 'react';

import cn from 'classnames';
import s from './tabs.module.scss';

const TabContent = ({ className, children }) => (
  <div className={cn(s.content, className)}>{children}</div>
);

export default TabContent;

import React, { useContext } from 'react';
import cn from 'classnames';
import { withTranslation } from 'react-i18next';
import { WidthContext } from 'contexts/width-context';
import { imageUrl } from 'utils';
import { LazyLoadImage } from 'react-lazy-load-image-component';
import { Link } from 'ui-kit';
import s from './book.module.scss';

const Book = ({
  id,
  img,
  info,
  match,
  title,
  authors,
  isAnimate,
  categories,
  description,
  scrollPosition,
  versions,
  thumbnail,
  className,
  w,
  h,
  i18n,
}) => {
  const { isTabDown, isRetina } = useContext(WidthContext);
  const DEFAUTL_BOOK_COVER = '/images/svg/defaultBook.svg';
  const lang = i18n.language;
  const image = thumbnail;
  let coefficient = 1;

  const imageSrc =
    (isRetina ? imageUrl(image, '340@2x') : imageUrl(image, '350')) ||
    DEFAUTL_BOOK_COVER;

  const placeholderSrc =
    imageUrl(image, '70') || '/images/svg/lazyLoadBook.svg';

  const DEFAULT_WIDTH = isTabDown ? 150 : 160;
  const DEFAULT_HEIGHT = isTabDown ? 203 : 216;

  if (w || h) {
    if (w) coefficient = DEFAULT_WIDTH / w;
    if (h) coefficient = DEFAULT_HEIGHT / h;
  }

  const width = Math.ceil(w || DEFAULT_WIDTH / coefficient);
  const height = Math.ceil(h || DEFAULT_HEIGHT / coefficient);

  const bookAuthors = authors
    ?.map(({ firstName, lastName }) => `${firstName[lang]} ${lastName[lang]}`)
    .join(',');

  return (
    <Link
      title={title}
      to={`/books/${id}`}
      style={{ width: `${width}px` }}
      className={cn(s.book, isAnimate && s.animate, className)}
    >
      <div className={s.image} style={{ height: `${height}px` }}>
        <LazyLoadImage
          effect="blur"
          src={imageSrc}
          className={s.front}
          wrapperClassName={s.frontW}
          scrollPosition={scrollPosition}
          placeholderSrc={placeholderSrc}
          onError={({ currentTarget }) => {
            currentTarget.src = DEFAUTL_BOOK_COVER;
          }}
          alt={`${title}, обложка, книга`}
        />
        <LazyLoadImage
          src={imageSrc}
          className={s.back}
          wrapperClassName={s.backW}
          scrollPosition={scrollPosition}
          placeholderSrc={placeholderSrc}
          onError={({ currentTarget }) => {
            currentTarget.src = DEFAUTL_BOOK_COVER;
          }}
          alt={`${title}, обложка книги`}
        />
        <div
          className={s.shadow}
          style={{
            // backgroundImage: `url(${img})`,
            // height: `${height * 0.92}px`,
            // width: `${width * 0.75}px`,
            height: `${height * 0.95}px`,
            width: `${width * 0.86}px`,
          }}
        />
      </div>
      <h3 className={s.name} title={title}>
        {title}
      </h3>
      <div className={s.author} title={bookAuthors}>
        {bookAuthors}
      </div>
    </Link>
  );
};

export default withTranslation()(Book);

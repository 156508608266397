import React from 'react';
import cn from 'classnames';
import { withTranslation } from 'react-i18next';
import { Helmet } from 'react-helmet';
import { useDataApi, NewBook, Issue } from 'components';
import { Container, Spin, Link, MainTitle, Accordion } from 'ui-kit';
import { Slider } from './components';
import s from './home.module.scss';

const Home = ({ t, className, match, scrollPosition }) => {
  const [
    { data: dataNewBooks, isLoading: isLoadingNewBooks },
  ] = useDataApi.getBooks({
    versions: true,
    limit: 6,
    offset: 0,
  });

  const [
    { data: banners, isLoading: isLoadingBanners },
  ] = useDataApi.getBanners({});

  const [
    { data: dataIssues, isLoading: isLoadingIssues },
  ] = useDataApi.getIssues({
    limit: 5,
    offset: 0,
  });

  const { books } = dataNewBooks;
  const { banner } = banners;
  const { issues } = dataIssues;
  return (
    <div className={cn(className, s.home, !isLoadingBanners && s.loading)}>
      <Helmet
        title="Home Page"
        meta={[{ name: 'description', content: 'Description for Home page.' }]}
      />
      <Container className={s.sliderContainer}>
        {isLoadingBanners ? (
          <Spin />
        ) : (
          banner && <Slider banner={banner} scrollPosition={scrollPosition} />
        )}
      </Container>
      <Container>
        <Container full={false} className={s.container}>
          <div className={s.categories}>
            <div className={s.category}>
              <div className={s.line} />
              <div className={s.count}>215</div>
              <div className={s.title}>Книги</div>
              <div className={s.description}>
                Коллектив издательства регулярно выпускает книги различной
                тематики для широкого круга читателей.
              </div>
              <Link to="/books">
                <img src="/images/svg/arrow-left-v2.svg" alt="" />
              </Link>
            </div>
            <div className={s.category}>
              <div className={s.line} />
              <div className={s.count}>112</div>
              <div className={s.title}>авторы</div>
              <div className={s.description}>
                Издаются книги отечественных и зарубежных авторов: ученых,
                публицистов, христианских писателей.
              </div>
              <Link to="/authors">
                <img src="/images/svg/arrow-left-v2.svg" alt="" />
              </Link>
            </div>
            <div className={s.category}>
              <div className={s.line} />
              <div className={s.count}>19</div>
              <div className={s.title}>периодика</div>
              <div className={s.description}>
                Ежемесячно в свет выходит ряд газет и журналов: «Вечное
                Сокровище», «Моя здоровая семья», «Дружболандия»...
              </div>
              <Link to="/books">
                <img src="/images/svg/arrow-left-v2.svg" alt="" />
              </Link>
            </div>
          </div>
        </Container>
        <Container full={false} className={s.container}>
          <div className={s.newBooks}>
            <div className={s.head}>
              <MainTitle texts={['Новые', 'Книги']} className={s.title} />
              <Link data-v2 to="/books" className={s.link}>
                Показать все
                <img src="/images/svg/arrow-left-v2.svg" alt="" />
              </Link>
            </div>
            {isLoadingNewBooks ? (
              <Spin />
            ) : (
              <div className={s.listNewBooks}>
                {books?.map((book, key) => (
                  <NewBook
                    {...book}
                    key={key}
                    match={match}
                    scrollPosition={scrollPosition}
                  />
                ))}
              </div>
            )}
          </div>
        </Container>
        <Container full={false} className={s.container}>
          <div className={s.magazines}>
            <div className={s.head}>
              <MainTitle texts={['Журналы &', 'газеты']} className={s.title} />
              <Link data-v2 to="/all-issues" className={s.link}>
                Показать все
                <img src="/images/svg/arrow-left-v2.svg" alt="" />
              </Link>
            </div>
            {isLoadingIssues ? (
              <Spin />
            ) : (
              <div className={s.listMagazines}>
                {issues?.map((issue, key) => (
                  <Issue
                    {...issue}
                    match={match}
                    key={key}
                    scrollPosition={scrollPosition}
                  />
                ))}
              </div>
            )}
          </div>
        </Container>
        <Container full={false} className={s.container}>
          <div className={s.ourMission}>
            <div className={s.head}>
              <MainTitle texts={[t('Наша'), t('миссия')]} className={s.title} />
              <Link data-v2 to="" className={s.link}>
                Подробнее
                <img src="/images/svg/arrow-left-v2.svg" alt="" />
              </Link>
            </div>
            <div className={s.description}>
              <p>{t('home.about1')}</p>
              <p>{t('home.about2')}</p>
            </div>
            <div className={s.image}>
              <img className={s.imageBook} src="/images/book.png" alt="" />
            </div>
            <div className={s.accortions}>
              <Accordion title={t('home.accordion.title1')}>
                <p>{t('home.accordion.text1.1')}</p>
                <p>{t('home.accordion.text1.2')}</p>
                <p>{t('home.accordion.text1.3')}</p>
                <p>{t('home.accordion.text1.4')}</p>
              </Accordion>
              <Accordion title={t('home.accordion.title2')}>
                <p>{t('home.accordion.text2.1')}</p>
                <p>{t('home.accordion.text2.2')}</p>
                <ul>
                  <li>{t('home.accordion.text2.3')}</li>
                  <li>{t('home.accordion.text2.4')}</li>
                  <li>{t('home.accordion.text2.5')}</li>
                  <li>{t('home.accordion.text2.6')}</li>
                  <li>{t('home.accordion.text2.7')}</li>
                  <li>{t('home.accordion.text2.8')}</li>
                  <li>{t('home.accordion.text2.9')}</li>
                </ul>
                <p>{t('home.accordion.text2.10')}</p>
              </Accordion>
              <Accordion title={t('home.accordion.title3')}>
                <p>{t('home.accordion.text3.1')}</p>
                <p>
                  <i>{t('home.accordion.text3.2')}</i>
                </p>
                <ul>
                  <li>{t('home.accordion.text3.3')}</li>
                  <li>{t('home.accordion.text3.4')}</li>
                  <li>{t('home.accordion.text3.5')}</li>
                  <li>{t('home.accordion.text3.6')}</li>
                  <li>{t('home.accordion.text3.7')}</li>
                </ul>
                <p>
                  <i>{t('home.accordion.text3.8')}</i>
                </p>
                <ul>
                  <li>{t('home.accordion.text3.9')}</li>
                  <li>{t('home.accordion.text3.10')}</li>
                  <li>{t('home.accordion.text3.11')}</li>
                  <li>{t('home.accordion.text3.12')}</li>
                </ul>
              </Accordion>
              <Accordion title={t('home.accordion.title4')}>
                <p>
                  <b>{t('home.accordion.text4.1')} </b>
                  {t('home.accordion.text4.2')}
                </p>
                <p>
                  <b>{t('home.accordion.text4.3')} </b>
                  {t('home.accordion.text4.4')}
                </p>
                <p>
                  <b>{t('home.accordion.text4.5')} </b>
                  {t('home.accordion.text4.6')}
                </p>
                <p>
                  <b>{t('home.accordion.text4.7')} </b>
                  {t('home.accordion.text4.8')}
                </p>
                <p>
                  <b>{t('home.accordion.text4.9')} </b>
                  {t('home.accordion.text4.10')}
                </p>
              </Accordion>
            </div>
          </div>
        </Container>
      </Container>
    </div>
  );
};

export default withTranslation()(Home);

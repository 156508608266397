import React, { useContext } from 'react';
import cn from 'classnames';
import { withTranslation } from 'react-i18next';
import { WidthContext } from 'contexts/width-context';
import { imageUrl } from 'utils';
import { Link } from 'ui-kit';
import { LazyLoadImage } from 'react-lazy-load-image-component';
import s from '../issue.module.scss';

const categories = {
  bjsjmrpb17cnl1skuj0g: 'Здоровье и семья',
  bjsjpp1b17cnl1skuj1g: 'Детские',
  bjsjpr1b17cnl1skuj20: 'Духовные',
  bjsjpupb17cnl1skuj2g: 'Научные',
  bjsjq2pb17cnl1skuj30: 'Труды Духа Пророчества',
  bk5ldun3m3t2qfm3kj90: 'Разные',
};

function IssueDetail({ t, i18n, issue, scrollPosition, switchModal }) {
  const {
    img,
    numberTotal,
    description,
    publicLayout,
    publicLayoutFull,
    periodical,
    periodicalId,
    publishYear,
  } = issue;

  const { isRetina } = useContext(WidthContext);
  const DEFAUTL_BOOK_COVER = '/images/svg/defaultBook.svg';

  const imageSrc =
    (isRetina ? imageUrl(img, '700@2x') : img) || DEFAUTL_BOOK_COVER;

  const placeholderSrc = imageUrl(img, '70') || '/images/svg/lazyLoadBook.svg';

  return (
    <div className={cn(s.block, s.detail)}>
      <div className={s.issue}>
        <LazyLoadImage
          alt=""
          src={imageSrc}
          wrapperClassName={s.background}
          scrollPosition={scrollPosition}
          onError={({ currentTarget }) => {
            currentTarget.src = DEFAUTL_BOOK_COVER;
          }}
        />
        <div className={s.image}>
          <LazyLoadImage
            effect="blur"
            src={imageSrc}
            alt={issue?.title}
            className={s.front}
            wrapperClassName={s.frontW}
            scrollPosition={scrollPosition}
            placeholderSrc={placeholderSrc}
            onError={({ currentTarget }) => {
              currentTarget.src = DEFAUTL_BOOK_COVER;
            }}
          />
          <LazyLoadImage
            src={imageSrc}
            alt={issue?.title}
            className={s.back}
            wrapperClassName={s.backW}
            scrollPosition={scrollPosition}
            placeholderSrc={placeholderSrc}
            onError={({ currentTarget }) => {
              currentTarget.src = DEFAUTL_BOOK_COVER;
            }}
          />
          <div className={s.shadow} />
        </div>
        <div className={s.info}>
          <div className={s.title}>
            {`${issue?.title} (${numberTotal}) ${publishYear}`}
          </div>
          <div className={s.prop}>
            <div className={s.propName}>{t(periodical?.type)}:</div>
            <Link data-v1 to={`/periodicals/${periodicalId}`}>
              {periodical?.title}
            </Link>
          </div>
          <div className={s.description}>{description}</div>
          <div className={s.prop}>
            <div className={s.propName}>{t('categories')}:</div>
            <Link data-v1 to={`/periodicals/${periodicalId}`}>
              {categories[periodical?.category]}
            </Link>
          </div>
          {(publicLayout || publicLayoutFull) && (
            <div className={s.download}>
              <div className={s.attachments}>
                <Link
                  to={publicLayoutFull || publicLayout}
                  className={s.eFormat}
                  target="__blank"
                >
                  <img
                    src={`/images/svg/${
                      (publicLayout || publicLayoutFull).match(
                        /pdf|mobi|epub$/gi
                      )[0]
                    }.svg`}
                    alt=""
                  />
                  {/* {publicLayoutFull ? 'Читать выпуск' : 'Читать фрагмент'} */}
                  {publicLayoutFull ? t('read issue') : t('read fragment')}
                </Link>
              </div>
              <p className={s.text}>
                Издательство «Джерело життя» предоставляет доступ к печатным
                материалам только для чтения.
              </p>
            </div>
          )}
        </div>
      </div>
    </div>
  );
}

export default withTranslation()(IssueDetail);

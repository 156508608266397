import React from 'react';
import cn from 'classnames';
import { withTranslation } from 'react-i18next';
import { Tab, Tabs, TabContainer, TabContent, TabsPanel, Link } from 'ui-kit';
import s from './book-detail.module.scss';

const BookTabs = ({ t, versions, eBooks, price, book, versionNumber }) => {
  // const BookTabs = ({ t, eBooks, price }) => {
  //   const bindings = {
  //     ['soft']: ['10x10'],
  //     ['hard']: ['5x5', '10x10', '15x15'],
  //     ['medium']: ['5x5', '15x15']
  //     }
  //   const sizes = {
  //     ['10x10']: ['soft', 'hard'],
  //     ['15x15']: ['hard', 'medium'],
  //     ['5x5']: ['medium', 'hard']
  //   }

  const isEBooks = eBooks.some((book) => /pdf|mobi|epub$/gi.test(book));

  return (
    <Tabs className={s.bookTabs}>
      <TabsPanel>
        <Tab icon="paper.svg" text={t('paper')} />
        <Tab icon="e-book.svg" text="EBOOK" disabled={!isEBooks} />
      </TabsPanel>
      <TabContainer className={s.container}>
        <TabContent className={cn(s.tabContent, s.paper)}>
          <div className={s.price}>
            {!!Math.sign(price) && (
              <div className={cn(s.bookProp, s.clean)}>
                <div className={s.propName}>{t('recommended price')}:</div>
                <div className={s.cost}>
                  {price} <span>{t('uah')}.</span>
                </div>
              </div>
            )}
            {!!versions[versionNumber - 1].info.binding && (
              <div className={cn(s.bookProp, s.clean)}>
                <div className={s.propName}>{t('cover type')}:</div>
                <div className={s.options}>
                  <div className={cn(s.bookOption, s.active)}>
                    <span>{`${t(
                      versions[versionNumber - 1].info.binding
                    )}`}</span>
                  </div>
                </div>
              </div>
            )}
            {!!versions[versionNumber - 1].info.width &&
              versions[versionNumber - 1].info.height && (
                <div className={cn(s.bookProp, s.clean)}>
                  <div className={s.propName}>
                    {`${t('format')}, ${t('mm')}`}:
                  </div>
                  <div className={s.options}>
                    <div className={cn(s.bookOption, s.active)}>
                      <span>
                        {`${versions[versionNumber - 1].info.width}
                        x${versions[versionNumber - 1].info.height}
                        `}
                      </span>
                    </div>
                  </div>
                </div>
              )}
          </div>
          <div className={s.payment}>
            <div className={cn(s.bookProp, s.clean)}>
              <div className={cn(s.propName)}>{t('where to buy')}:</div>
              <div className={s.payments}>
                <Link to="https://bookson.com.ua/" className={s.paymentMethod}>
                  <img
                    className={s.logo}
                    src="/images/svg/bookson.svg"
                    alt=""
                  />
                  <img
                    className={s.arrow}
                    src="/images/svg/arrow-left.svg"
                    alt=""
                  />
                </Link>
                {/* <div className={s.paymentMethod}>
                  <img className={s.logo} src="/images/svg/amazon.svg" alt="" />
                  <img className={s.arrow} src="/images/svg/arrow-left.svg" alt=""/>
                  </div>
                  <div className={s.paymentMethod}>
                  <img className={s.logo} src="/images/svg/google-play.svg" alt="" />
                  Google Play
                  <img className={s.arrow} src="/images/svg/arrow-left.svg" alt=""/>
                  </div>
                  <div className={s.paymentMethod}>
                  <img className={s.logo} src="/images/svg/apple-books.svg" alt="" />
                  Apple Books
                  <img className={s.arrow} src="/images/svg/arrow-left.svg" alt=""/>
                </div> */}
              </div>
            </div>
            <div className={s.description}>
              Издательство «Джерело життя» не является продавцом, но
              предоставляет информацию для ознакомления
            </div>
          </div>
        </TabContent>
        <TabContent className={s.tabContent}>
          <div className={cn(s.bookProp, s.clean)}>
            <div className={s.propName}>{t('Download book for free')}:</div>
          </div>
          <div className={s.eFormats}>
            {eBooks.map((book) => {
              const format = book.match(/pdf|mobi|epub$/gi);
              return (
                format && (
                  <Link key={book} to={book} className={s.eFormat}>
                    <img src={`/images/svg/${format}.svg`} alt="" />
                    {format}
                  </Link>
                )
              );
            })}
          </div>
          <div className={s.description}>
            Издательство «Джерело життя» не является продавцом, но предоставляет
            информацию для ознакомления
          </div>
        </TabContent>
      </TabContainer>
    </Tabs>
  );
};

export default withTranslation()(BookTabs);

/* eslint-disable react/no-unused-state */
import React, { Component } from 'react';

import { default as UploadFile } from 'react-dropzone';
import cn from 'classnames';
import s from './dropzone.module.scss';

class Dropzone extends Component {
  state = {
    file: {},
    dropzone: {
      isDragEnter: false,
      isDragLeave: false,
    },
  };

  onDrop = (acceptedFiles) => {
    const { onDrop } = this.props;
    this.setState({
      dropzone: {
        isDragEnter: false,
        isDragLeave: false,
      },
    });

    acceptedFiles.forEach((element) => {});
    (file) => {
      file.preview = URL.createObjectURL(file);
      const i = new Image();

      i.onload = () => {
        const reader = new FileReader();

        reader.readAsDataURL(file);

        reader.onload = () => {
          // const {updateState, toast} = this.props
          // const {name, requirements, path} = toast
          const { preview } = file;
          const { width, height } = i;

          // setState({
          //   file: preview
          // })

          onDrop(preview, width, height);
          // let errors = []

          // TODO: need validation
          // if (name != newFileName) {
          //   errors.push('Check the correct file name!')
          // }
          //
          // if (size > 3000) {
          //   errors.push('Check the correct image size!')
          // }

          // if (requirements.width != width) {
          //   errors.push('Check the correct image width!')
          // }
          //
          // if (requirements.height != height) {
          //   errors.push('Check the correct image height!')
          // }

          // TODO: need future review front + back upload files.
          // client.mutate({
          //   mutation: UPLOAD_ASSET,
          //   variables: {
          //     file: {...file, stream: reader.result},
          //     path,
          //     slug: this.props.slug
          //   }
          // })
          // .then(
          //   data => {
          //     console.log('!!!!', data)
          //   })
          // .catch(console.error);

          // this.setState({
          //   file: preview
          // })
        };
      };

      i.src = file.preview;
    };
  };

  onDragEnter = () => {
    this.setState({
      dropzone: {
        isDragEnter: true,
        isDragLeave: false,
      },
    });
  };

  onDragLeave = () => {
    this.setState({
      dropzone: {
        isDragEnter: false,
        isDragLeave: true,
      },
    });
  };

  render() {
    const { className, children, onDrop, ...props } = this.props;
    const { isDragEnter } = this.state;
    return (
      <UploadFile
        // noClick={true} // disabled click
        // ref={dropzoneRef}
        onDrop={this.onDrop}
        accept="image/png, image/jpeg"
        onDragEnter={this.onDragEnter}
        onDragLeave={this.onDragLeave}
        {...props}
      >
        {({ getRootProps, getInputProps }) => (
          <div
            {...getRootProps()}
            className={cn(
              s.dropzone,
              className,
              isDragEnter ? s.dragEnter : s.dragLeave
            )}
          >
            <input {...getInputProps()} />
            {children}
            {/* {file ? (
              <div className={s.text}>
                <span>click</span>
                or
                <span>drop images here</span>
              </div>
              ) : (
              <div className={s.withFile}>
                <img src={file} alt="" />
              </div>
            )} */}
          </div>
        )}
      </UploadFile>
    );
  }
}

export default Dropzone;

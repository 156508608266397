import React from 'react';
import cn from 'classnames';

import s from './control-tooltip.module.scss';

const ControlTooltip = ({ handleSave, handleCancel, title, isShow }) => {
  return (
    <div className={cn(s.controlTooltip, isShow ? s.show : s.hide)}>
      <div className={s.action} onClick={handleSave}>
        <img src="" alt="" />
        Save
      </div>
      <div className={s.action} onClick={handleCancel}>
        <img src="" alt="" />
        Cancel
      </div>
      <div className={s.info}>
        <div className={s.title}>{title}</div>
        <div className={s.validation}>15/200</div>
      </div>
      <div className={s.wrap}>
        <div className={s.triangle} />
      </div>
    </div>
  );
};

export default ControlTooltip;

const en = {
  lifesource: 'Life Source',
  'christian-publishing': 'christian publishing',
  Home: 'Home',
  Books: 'Books',
  books: 'books',
  'new books': 'new books',
  periodicals: 'periodicals',
  contacts: 'contacts',
  authors: 'authors',
  author: 'author',
  'Home page': 'Home Page',
  magazine: 'magazine',
  newspaper: 'newspaper',
  freq: 'frequency',
  copies: 'copies',
  monthly: 'monthly',
  Monthly: 'monthly',
  Quarterly: 'quarterly',
  quarterly: 'quarterly',
  bimonthly: 'bimonthly',
  Bimonthly: 'Bimonthly',
  semiannually: 'semiannually',
  Semiannually: 'Semiannually',
  annually: 'annually',
  Annually: 'Annually',
  'on bracket': 'on bracket',
  soft: 'soft',
  hard: 'hard',
  search: 'search',
  'search results': 'search results',
  'no matches for': 'no matches for',
  'all-issues': 'All issues',
  categories: 'categories',
  paper: 'paper',
  'recommended price': 'recommended price',
  uah: 'uah',
  'cover type': 'cover type',
  format: 'format',
  mm: 'mm',
  'where to buy': 'where to buy',
  'download book for free': 'download book for free',
  evg: 'evangelist',
  writer: 'writer',
  'read all book': 'Read all book',
  'read fragment': 'Read fragment',
  'read issue': 'Read Issue',
};

export default en;

import React, { useContext } from 'react';
import cn from 'classnames';
import { withTranslation } from 'react-i18next';
import { WidthContext } from 'contexts/width-context';
import { imageUrl } from 'utils';
import { Link } from 'ui-kit';
import { LazyLoadImage } from 'react-lazy-load-image-component';
import s from './periodical.module.scss';

const Periodical = ({
  t,
  id,
  img,
  info,
  match,
  title,
  isAnimate,
  categories,
  description,
  scrollPosition,
  type,
  versions,
  thumbnail,
  className,
  i18n,
}) => {
  const { isRetina } = useContext(WidthContext);
  const DEFAUTL_BOOK_COVER = '/images/svg/defaultBook.svg';

  const image = img;

  const imageSrc =
    (isRetina ? imageUrl(image, '340@2x') : imageUrl(image, '350')) ||
    DEFAUTL_BOOK_COVER;

  return (
    <Link
      title={title}
      to={`/periodicals/${id}`}
      className={cn(s.periodical, isAnimate && s.animate, className)}
    >
      <div className={s.images}>
        <div className={cn(s.image, !image && s.default)}>
          <LazyLoadImage
            alt={title}
            src={imageSrc}
            className={s.front}
            wrapperClassName={s.frontW}
            scrollPosition={scrollPosition}
            onError={({ currentTarget }) => {
              currentTarget.src = DEFAUTL_BOOK_COVER;
            }}
          />
          <div className={s.shadow} />
        </div>
        <div className={cn(s.image, !image && s.default)}>
          <LazyLoadImage
            alt={title}
            src={imageSrc}
            className={s.front}
            wrapperClassName={s.frontW}
            scrollPosition={scrollPosition}
            onError={({ currentTarget }) => {
              currentTarget.src = DEFAUTL_BOOK_COVER;
            }}
          />
          <div className={s.shadow} />
        </div>
        <div className={cn(s.image, !image && s.default)}>
          <LazyLoadImage
            alt={title}
            src={imageSrc}
            className={s.front}
            wrapperClassName={s.frontW}
            scrollPosition={scrollPosition}
            onError={({ currentTarget }) => {
              currentTarget.src = DEFAUTL_BOOK_COVER;
            }}
          />
          <div className={s.shadow} />
        </div>
      </div>
      <h3 className={s.name} title={title}>
        {title}
      </h3>
      <div className={s.type}>{t(type)}</div>
    </Link>
  );
};

export default withTranslation()(Periodical);

import React, { Children } from 'react';

import cn from 'classnames';
import s from './tabs.module.scss';

const TabContainer = ({ active, className, ...props }) => {
  const childrens = Children.toArray(props.children);
  return (
    <div className={cn(s.container, className)}>
      {childrens.filter((contentActiveTab, index) =>
        active === index ? contentActiveTab : null
      )}
    </div>
  );
};

export default TabContainer;
